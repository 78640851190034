@import '~smv-assets/css/variables.scss';

.data-weight {
  border: 1px solid $smv-background-light;
  margin-bottom: 1rem;
  padding: 8px;
  position: relative;

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 0;
    width: 100%;
    opacity: 0.2;
    border-radius: 3px;

    background-color: transparent;
    transition: background-color 500ms, height 0ms 500ms;
  }

  &.smv-scroll-indicator {
    &:after {
      z-index: 2;
      height: 100%;
      background-color: $smv-primary;
      transition: background-color 250ms;
    }
  }

  .fas {
    font-size: 0.88rem;
  }

  .copy-weight-input {
    display: inline-block;
    position: relative;
    width: 50%;
    min-width: 200px;

    input {
      font-size: 0.75rem;
      text-overflow: ellipsis;
      &:focus {
        text-overflow: ellipsis;
        box-shadow: none;
      }
    }

    @keyframes fadeInOutMoveUp {
      0% {
        opacity: 0;
        transform: translateY(0);
      }
      25% {
        opacity: 1;
        transform: translateY(-10px);
      }
      75% {
        opacity: 1;
        transform: translateY(-10px);
      }
      100% {
        opacity: 0;
        transform: translateY(-20px);
      }
    }

    .copied-notification {
      position: absolute;
      top: -30px;
      right: 0;
      background-color: #28a745;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      animation: fadeInOutMoveUp 1200ms;
      pointer-events: none;
      z-index: 1000;
    }
  }
  .weight-info {
    background-color: $smv-background-lighter;
    font-size: 0.75rem;
    border: 0;
  }
}

.data-weight-definition {
  font-size: 0.75rem;
  padding: 8px;
  margin-bottom: 8px;
  background-color: $smv-background-lighter;
  border: none;

  input,
  select {
    font-size: 0.75rem;
  }

  .custom-select,
  .col-form-label {
    font-size: 0.75rem;
  }
}
.data-weight-definition__headline {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > div {
    width: 50%;
  }
}

.data-weight-definition-table {
  max-width: 100%;
  overflow-x: auto;

  .cell--excluded {
    color: $smv-background;
  }

  .desired-distribution-input {
    max-width: 80px;
    &--warning {
      border-color: $smv-warning;
    }
  }
}

.data-weight__title {
  input {
    min-width: 150px;
    max-width: 350px;
  }
}

.data-weight,
.weight-protocol-modal {
  .weighting-message {
    &--error {
      color: $smv-warning;
    }
    &--success {
      color: $smv-primary;
    }
  }
}
