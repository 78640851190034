/* Custom CSS for Bootstrap v4-like utility classes */

/* Margin Left */
.ml-0 {
  margin-left: 0 !important;
}
.ml-1 {
  margin-left: 0.25rem !important;
}
.ml-2 {
  margin-left: 0.5rem !important;
}
.ml-3 {
  margin-left: 1rem !important;
}
.ml-4 {
  margin-left: 1.5rem !important;
}
.ml-5 {
  margin-left: 3rem !important;
}

/* Margin Right */
.mr-0 {
  margin-right: 0 !important;
}
.mr-1 {
  margin-right: 0.25rem !important;
}
.mr-2 {
  margin-right: 0.5rem !important;
}
.mr-3 {
  margin-right: 1rem !important;
}
.mr-4 {
  margin-right: 1.5rem !important;
}
.mr-5 {
  margin-right: 3rem !important;
}

/* Padding Left */
.pl-0 {
  padding-left: 0 !important;
}
.pl-1 {
  padding-left: 0.25rem !important;
}
.pl-2 {
  padding-left: 0.5rem !important;
}
.pl-3 {
  padding-left: 1rem !important;
}
.pl-4 {
  padding-left: 1.5rem !important;
}
.pl-5 {
  padding-left: 3rem !important;
}

/* Padding Right */
.pr-0 {
  padding-right: 0 !important;
}
.pr-2 {
  padding-right: 0.5rem !important;
}
.pr-3 {
  padding-right: 1rem !important;
}
.pr-4 {
  padding-right: 1.5rem !important;
}
.pr-5 {
  padding-right: 3rem !important;
}

/* Text Alignment */
.text-left {
  text-align: left !important;
}
.text-right {
  text-align: right !important;
}
.text-justify {
  text-align: justify !important;
}

/* Float */
.float-left {
  float: left !important;
}
.float-right {
  float: right !important;
}
