@import '~smv-assets/css/variables.scss';

.inline-edit {
  input {
    border: 1px solid transparent;
    color: $smv-font;
    text-overflow: ellipsis;
    max-width: 100%;

    &:hover {
      border: 1px solid $smv-background;
      border-radius: 2px;
    }
  }
  &--empty {
    input {
      font-style: italic;
    }
  }
}
