@import '~smv-assets/css/variables.scss';

.available-filter-list {
  height: calc(100% - 20px);
  overflow-y: auto;
  border-radius: 0.25rem;
  background-color: $smv-background-light;

  .filter-header {
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    min-height: 34px;
    width: 100%;
    padding: 9px 0 9px 9px;
    background: $smv-background;
    width: 100%;
    cursor: pointer;

    i {
      margin-left: 2px;
    }
  }
  .collapse {
    width: 100%;
  }
}

.available-filter-list .node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 42px;

  span {
    margin-left: 8px;
  }

  img {
    width: 14px;
  }

  .node__title {
    flex-grow: 1;
    margin-left: 10px;
  }

  @for $i from 1 through 9 {
    &.structure-level-#{$i} {
      margin-left: ($i - 1) * 10px;
    }
  }

  &.structure-level-0 {
    padding-left: 0px;
    border-left: none;
    margin-left: 0px;
  }
}

.available-filter-list__collapse {
  padding: 8px 4px 2px 4px;
  background-color: $smv-background-light;
}
