@import '~smv-assets/css/variables.scss';

.choiceItem__header {
  display: flex;
  align-items: center;
  padding: 4px;
  background-color: $smv-white;
  font-weight: 500;

  > div {
    width: 30px;
  }

  .choiceItem__header-label {
    flex: 1;
  }

  .choiceItem__header-value {
    flex-basis: 80px;
  }

  .choiceItem__header-options {
    flex-basis: 90px;
  }
}

.config-choices__headline {
  padding: 4px 0;
  font-weight: 500;
}

.config-choices__head {
  width: 100%;
  background: $smv-background-light;
  height: 36px;
  padding: 8px 12px 4px 12px;
  margin-bottom: -4px;
  display: flex;
  align-items: center;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  overflow-y: scroll;

  > i,
  > div {
    width: 30px;
  }

  .config-choices__head-name {
    flex: 1 1 auto;
    padding: 0 4px;
    font-weight: 500;
  }
  .config-choices__head-value {
    flex-basis: 80px;
    font-weight: 500;
  }
}

.config-choices-droppable {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 1px;
  overflow-y: scroll;
  max-height: 320px;
}
.config-choices-droppable--disabled {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
