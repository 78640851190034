@import '~smv-assets/css/variables.scss';

.smv-dashboard-element--media {
  &.smv-dashboard-element {
    .card-body {
      padding-top: 2rem;
      padding-bottom: 1.75rem;
    }
  }

  .dashboard-alignment-buttons {
    position: absolute;
    right: 12px;
    top: 8px;
    margin: 0 auto;
    text-align: center;
    opacity: 0;
    transition: opacity 200ms ease-in-out;

    .fas,
    .fal {
      cursor: pointer;
      color: $smv-secondary;
      font-size: 1rem;
    }
  }

  .dashboard-image-container {
    position: relative;
    width: 100%;
    max-width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    overflow: hidden;

    &--column {
      flex-direction: column;
    }

    &:hover {
      .dashboard-image-container__edit-btn {
        opacity: 1;
      }
    }
    .av-media {
      height: 100%;
      width: 100%;
      justify-content: center;
      align-items: center;

      video {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
  }

  .dashboard-image__placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .dashboard-image-container__edit-btn {
    position: absolute;
    right: 0px;
    top: 0px;
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: 2;

    button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $smv-white;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .single-image-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .dashboard-image {
    display: block;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    object-fit: contain;
  }

  // react-slick slider css fixes
  .dashboard-carousel {
    height: 100%;
    .slider-item {
      height: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;

      &--video,
      &--image {
        cursor: pointer;
      }

      video,
      audio,
      img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
      }
    }
    .slick-track,
    .slick-slide,
    .slick-list,
    .single-image-container {
      height: 100%;
    }
    .slick-slide {
      > div {
        height: 100%;
      }
    }
    .slick-arrow {
      z-index: 5;
    }
    .slick-prev {
      left: 0;
      &:before {
        color: $smv-background;
      }
    }
    .slick-next {
      right: 0;
      &:before {
        color: $smv-background;
      }
    }
  }
}
