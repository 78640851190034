@import '~smv-assets/css/variables.scss';
.exports-center {
  position: relative;

  .dropdown-menu {
    max-height: 300px;
    overflow: auto;
    width: 480px;
  }
  .exports-window {
    .exports-window__close-button {
      position: absolute;
      right: 8px;
      top: 12px;
      color: $smv-font;
      z-index: 1;
    }

    .exports-window__list {
      display: flex;
      flex-direction: column;
    }

    .export-item {
      padding: 12px 8px 12px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: background-color 200ms ease-in-out;
      cursor: default;

      &--finished {
        cursor: pointer;
      }

      &:hover {
        background-color: $smv-background-lighter;
      }

      .export-item__type {
        margin-right: 8px;
        flex: 0 1 auto;
        color: $smv-secondary;
      }
      .export-item__info-block {
        flex: 1 1 auto;
      }
      .export-item__name {
        font-weight: bold;
        font-size: 0.88rem;
      }
      .export-item__date {
        line-height: 1;
        font-size: 0.88rem;
      }
      .export-item__project-info {
        line-height: 1;
        font-size: 0.88rem;
      }
      .export-item__download {
        flex: 0 1 auto;
        width: 32px;
        height: 32px;
        font-size: 0.88rem;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px;
        border-radius: 50%;
        border: 1px solid $smv-background-light;
        transition: background-color 200ms ease-in-out, border-color 200ms ease-in-out;

        &--ready {
          &:hover {
            color: $smv-secondary;
            border-color: $smv-background;
            background-color: $smv-background-light;
          }
        }

        .fa-download {
          cursor: pointer;
          color: $smv-secondary;
        }
      }
    }
  }
}
