@import '~smv-assets/css/variables.scss';

.reports-list {
  padding: 0.75rem 0.5rem;

  .reports-list__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;

    .fa-edit {
      font-size: 0.75rem;
    }

    .reports-list__headline-text {
      word-break: break-all;
      flex: 2 1 auto;
      width: 100%;
    }
  }
}
