@import '~smv-assets/css/variables.scss';

.billing-survey-cost-table {
  font-size: 0.75rem;

  th {
    font-weight: bold;
  }
  tbody td:first-of-type {
    font-weight: bold;
  }
  tbody tr:first-of-type {
    td {
      border-top: 2px solid $smv-background-dark;
    }
  }
  tbody tr:last-of-type {
    td {
      border-bottom: none;
    }
  }
}
