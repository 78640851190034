@import '~smv-assets/css/variables.scss';

.shared-choice {
  display: flex;
  align-items: center;

  .fal {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  &:hover {
    .ql-editor {
      border: 1px solid $smv-background-light;
    }
  }
  .shared-choice__value {
    font-size: 0.75em;
    padding-left: 4px;
    padding-right: 4px;

    &--warning {
      color: $smv-warning;
      border: 1px solid $smv-warning;
    }
  }
}
