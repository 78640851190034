.editabletext-jodit {
  position: relative;

  // &--loading {
  //   &:after {
  //     content: "";
  //     position: absolute;
  //     background-color: #ebebeb;
  //     left: 0;
  //     right: 0;
  //     top: 0;
  //     bottom: 0;
  //     opacity: 0.5;
  //   }
  // }

  .jodit-container {
    min-height: 80px !important;
  }

  .jodit-workplace,
  .jodit-wysiwyg {
    min-height: 32px !important;

    p {
      margin-bottom: 0px;
    }
  }
  // .jodit-status-bar {
  //   display: none;
  // }

  .jodit-add-new-line {
    display: none;
  }

  .jodit-audio-html-element,
  .jodit-video-html-element {
    z-index: 1 !important;
  }
}

.jodit-popup-container,
.jodit-box {
  // display: none;
  // .jodit-toolbar-button:not([ref="bin"]):not([ref="delete"]) {
  // display: none;
  // }
  .jodit-toolbar-button[ref='valign'],
  .jodit-toolbar-button[ref='left'] {
    display: none;
  }
}
