@import '~smv-assets/css/variables.scss';

.smoove-question-container {
  position: relative;

  &:hover {
    .choice-config-container {
      opacity: 1;
    }
  }

  .question-title-group {
    display: flex;
    color: $smv-font-light;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: nowrap;
    flex: 0 0 auto;
    max-width: 66%;

    .question-title {
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
  }

  .question-title {
    flex: 0 0 auto;
    max-width: 100%;

    .question-title__varname {
      text-transform: uppercase;
      display: flex;
      align-items: center;
      flex-wrap: nowrap;
    }
    .question-title__title {
      text-transform: uppercase;
      max-width: 90%;
    }
  }

  .question-icons {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    align-items: center;
  }

  .que-input-field {
    height: 28px;
    font-size: 0.75rem;
    margin-top: 2px;

    &--multiline {
      height: auto;
      max-height: 28px;
      padding: 0.25rem 0.7rem;
    }
  }
  .que-input-field.form-control:disabled,
  .que-input-field.form-control[readonly] {
    background-color: $smv-background-lighter;
    width: 100%;
    max-width: 150px;
  }
}

.question-loading-overlay {
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(233, 233, 233, 0.5);
}

.question-title__title.inline-edit {
  input {
    max-width: 100%;
    text-overflow: ellipsis;
  }
}

.matrix-overflow-scroll {
  display: block;
  overflow-x: auto;
}
