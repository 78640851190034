@import '~smv-assets/css/variables.scss';

.table-tree {
  padding: 2px 0px 2px 8px;
  list-style: none;
  margin-left: 6px;
  border-left: 1px solid $smv-background-light;
}

.table-tree--dragging-source {
  opacity: 0.3;
}

.table-tree--placeholder-container {
  position: relative;
}

.table-tree--drop-target {
  background-color: $smv-background-light;
}
