@import '~smv-assets/css/variables.scss';

.scaleItem {
  display: flex;
  align-items: center;
  padding: 4px;

  > div {
    width: 30px;
  }

  .scaleItem__grab {
    cursor: grab;
    text-align: center;
  }

  .scaleItem__label {
    flex: 1;
    background-color: white;
    border-radius: 0.25rem;
  }

  .scaleItem__value {
    flex-basis: 80px;
    &--warning {
      input {
        border: 1px solid $smv-warning;
      }
    }
    .scaleitem-input {
      &--invalid {
        border: 1px solid $smv-danger;
      }
    }
    .scaleitem-danger {
      color: $smv-danger;
    }
  }

  .scaleItem__trash {
    text-align: center;
  }
}

.scaleItem--new {
  background-color: $smv-background-light;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 8px;

  .scaleItem__label .ql-editor {
    padding-left: 8px !important;
  }
}

.config-scale__headline {
  padding: 4px 0;
  font-weight: 500;
}

.config-scale-droppable {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-bottom: 1px;
}
.config-scale-droppable--disabled {
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
