@import '~smv-assets/css/variables.scss';

.tables-list {
  padding: 0.75rem 0.5rem;

  .tables-list__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .tables-list__headline-text {
      word-break: break-all;
      flex: 2 1 auto;
    }
  }
}
