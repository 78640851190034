@import '~smv-assets/css/variables.scss';

.config-bar-top {
  display: flex;
  align-items: center;

  .config-bar-top__devices {
    display: flex;
    align-items: center;

    .fal {
      // margin-left: 8px;
      font-size: 1.5rem;
    }

    .device {
      padding-left: 0.325rem;
      padding-right: 0.325rem;
      &:hover {
        cursor: pointer;
      }

      &--active {
        color: $smv-primary;
      }

      &--disabled {
        color: $smv-background;
        &:hover {
          cursor: not-allowed;
        }
      }
    }
  }
}
