@import '~smv-assets/css/variables.scss';

$sig-high-color: rgb(197, 225, 165);
$sig-high-color-fade: rgba(197, 225, 165, 0.4);
$sig-high-color-border: rgb(117, 163, 63);

$sig-low-color: rgb(255, 205, 210);
$sig-low-color-fade: rgb(255, 205, 210, 0.4);
$sig-low-color-border: rgb(223, 87, 100);

$sig-mutually-color: rgb(225, 190, 231);
$sig-mutually-color-fade: rgb(225, 190, 231, 0.4);
$sig-mutually-color-border: rgba(172, 87, 187, 0.4);

.smv-result-table {
  border: solid 1px #ebebeb;
  overflow: auto;
  max-height: 65vh;
  border-radius: 0.25rem;
  position: relative;
  box-sizing: border-box;

  .table > :not(caption) > * > * {
    border-bottom-width: 0px;
    &.sig__low--to-hovered,
    &.sig__high--to-hovered {
      border-bottom-width: 1px;
    }
  }

  table {
    margin: 0;
  }

  .head__row {
    display: table-row;

    .head__header {
      .head__header_label {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

    .head__column {
      display: table-cell;
      width: 40px;

      .head__column_content {
        display: -webkit-box;
        /* 
          solution for word wrapping and multiline ellipsis
          6 lines is adjusted to 120px max height and the font size 12px
        */
        line-clamp: 6; // Adjust this value to control the number of lines : e.g, max-height 80px = 4 lines
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        align-items: center;
        justify-content: center;

        max-height: 120px;
        align-items: flex-start;
        justify-content: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
        word-wrap: break-word;
      }

      &.head__column_hidden {
        color: $smv-background;
      }
    }
  }

  .result__row {
    display: table-row;
    // border-bottom: 1px solid $smv-background;
    border-top: 1px solid $smv-background-light;

    .row__header {
      .row__header_label {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &.row__header--hidden .row__header_label .row__header_label--label {
        opacity: 0.5;
      }

      .smv-custom-dropdown {
        .dropdown-header {
          font-size: 0.75rem;
          color: $smv-background-dark;
          padding: 0.5rem;
          margin: 0;
        }

        .smv-custom-dropdown--nested {
          .dropdown-item {
            margin: 0;
            padding: 0.5rem;
          }
        }

        .dropdown-item {
          &.active {
            color: $smv-font-dark;
            background-color: $smv-background-light;
            font-weight: bold;
            i {
              color: $smv-font-dark;
            }
            span {
              color: $smv-font-dark;
              font-weight: bold;
            }
          }
        }
      }
    }

    .smv-result-table__result-question-selection-details {
      padding-top: 0.75rem;
      font-size: 0.7rem;
      line-height: 0.8rem;
      font-weight: normal;
      font-style: italic;

      &--loop-iteration {
        padding-left: 0.3rem;
        padding-bottom: 0.5rem;

        &-source {
          font-weight: bold;
        }
        &-selected {
          text-decoration: underline;
        }
      }
    }

    td.result__cell {
      display: table-cell;
      line-height: 0.8rem;
      vertical-align: middle;
      text-align: center;
      transition: font-size 200ms ease, background-color 200ms ease;

      &--hidden {
        opacity: 0.5;
      }
      &--excluded {
        font-style: italic;
        opacity: 0.5;
      }
      &--hovered {
        font-weight: bold;
        font-size: 0.8rem;
      }
      &--hovered-cell {
        border: 1px solid rgb(60, 60, 60);
      }
    }
  }

  th.header__empty,
  th.result__empty {
    text-align: center;
    line-height: 100px;
    color: $smv-font-light;
  }

  .sig__high {
    background-color: $sig-high-color;
    &--to-hovered {
      border: 1px solid $sig-high-color-border;
      font-weight: bold;
      font-size: 0.8rem;
    }
    &.sig__not-hovered {
      background-color: $sig-high-color-fade;
    }
  }

  .sig__low {
    background-color: $sig-low-color;
    &--to-hovered {
      border: 1px solid $sig-low-color-border;
      font-weight: bold;
      font-size: 0.8rem;
    }
    &.sig__not-hovered {
      background-color: $sig-low-color-fade;
    }
  }

  .sig__mutually {
    background-color: $sig-mutually-color;
    &--to-hovered {
      border: 1px solid $sig-mutually-color-border;
      font-weight: bold;
      font-size: 0.8rem;
    }
    &.sig__not-hovered {
      background-color: $sig-mutually-color-fade;
    }
  }

  .question_text {
    display: flex;
    p {
      margin: 0;
    }
  }

  .table--loading {
    filter: blur(4px);
  }
}

.sig__icon {
  margin: 0px 4px;
  border: 1px solid gray;
  width: 0.8rem;
  height: 0.8rem;

  &.sig__icon--mutually {
    background-color: $sig-mutually-color;
  }

  &.sig__icon--high {
    background-color: $sig-high-color;
  }

  &.sig__icon--low {
    background-color: $sig-low-color;
  }
}

.smv-result-table__head .head__row:nth-of-type(1) {
  th {
    height: 42px;
    position: sticky;
    top: 0;
    z-index: 3;
    background: white;
    border-bottom: none;
    border-top: none;
  }
}
.smv-result-table__head .head__row:nth-of-type(2) {
  th {
    position: sticky;
    top: 42px;
    z-index: 2;
    background: white;
    border-bottom: none;
    border-top: none;
  }
}

.smv-result-table__draft {
  background-color: $smv-warning;
  text-align: center;
  color: white;
}

.smv-result-table__body--can-drop,
.smv-result-table__head--can-drop {
  background-color: rgba($smv-secondary, 0.5);
  border: 2px dashed $smv-secondary;
  .head__row {
    th {
      background-color: rgba($smv-secondary, 0.5) !important;
    }
  }
}

.smv-result-table__body--is-over,
.smv-result-table__head--is-over {
  background-color: rgba($smv-primary, 1);
  .head__row {
    th {
      background-color: rgba($smv-secondary, 1) !important;
    }
  }
}
