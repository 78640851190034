@import '~smv-assets/css/variables.scss';

.smv-drawer {
  //background-color: rgba(229, 229, 229, 0.8);
  background-color: white;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 200;
  padding-top: 80px;
  padding-left: 15px;
  padding-right: 15px;
  transition: transform 180ms ease-in-out;
  width: 80%;

  @media (min-width: $breakpoint-md) {
    width: 40%;
  }

  .smv-drawer__closebtn {
    position: absolute;
    top: 24px;
    padding: 8px;
    font-size: 1.25rem;
    cursor: pointer;
  }

  // reports specific, might need to move these stlyes!
  .reports-list,
  .smv-filter-list,
  .tables-list {
    border: none;
    .fa-chevron-down,
    .fa-chevron-right {
      width: 25px;
      font-size: 1rem;
      padding-left: 0 !important;
    }
  }
}

.smv-drawer--left {
  left: 0;
  transform: translateX(-110%);
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5);

  .smv-drawer__closebtn {
    right: 10px;
  }

  &.smv-drawer--open {
    transform: translateX(0);
  }
}

.smv-drawer--right {
  right: 0;
  transform: translateX(100%);

  .smv-drawer__closebtn {
    left: 10px;
  }

  &.smv-drawer--open {
    transform: translateX(0);
    overflow-y: auto;
  }
}

.smv-brackdrop {
  position: fixed;
  width: 0;
  height: 0%;
  background-color: transparent;
  z-index: 100;
  top: 0;
  right: 0;
  transition: background-color 200ms ease-in-out;
}
.smv-backdrop--open {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
