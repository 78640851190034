.question-preview-modal {
  .modal-header {
    border-bottom: none;
  }

  .modal-body {
    min-height: 50vh;
  }

  .question-preview {
    min-height: 100%;
    hr {
      border-top: 1px solid #494949;
      margin-top: 2px;
      margin-bottom: 2px;
    }

    .question-preview__que-text {
      font-family: Figtree;
      font-size: 1.5rem;
      overflow: auto;
      p {
        margin-bottom: 0;
      }
    }

    .question-preview__que-instruction {
      font-size: 1.125rem;
      font-family: Figtree;
    }

    .choice__label,
    .scale__label {
      font-family: Figtree;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }
    .preview-choices-list {
      &--horizontal {
        display: flex;
        flex-wrap: wrap;
        .previewitem__choice {
          max-width: calc(25% - 8px);
          min-width: 220px;
        }
      }
      &--alternate-background-colors {
        .previewitem__choice {
          &:nth-child(even) {
            background-color: #ebebeb;
          }
          // &:hover {
          //   background-color: #6e9ab7;
          // }
        }
      }
      &--display-cards {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .previewitem__choice {
          display: flex;
          justify-content: center;
          align-items: center;
          // max-width: 170px;
          padding: 16px;
          margin: 4px;
          border: 1px solid #ebebeb;
          max-width: calc(25% - 8px);
          min-width: 220px;
          .choice__radio {
            display: none;
          }
        }
      }
      .previewitem__choice {
        &:hover {
          background-color: #6e9ab7;
          color: white;
        }
      }
    }
    .previewitem__choice {
      display: flex;
      align-items: center;
      padding: 8px;
      cursor: pointer;

      .choice__radio,
      .choice__checkbox {
        margin-right: 8px;
        .circle,
        .checkbox {
          background-color: white;
          border: 1px solid #315667;
          display: block;
          width: 16px;
          height: 16px;
        }
        .circle {
          border-radius: 50%;
        }
      }

      &--selected {
        .choice__radio,
        .choice__checkbox {
          .circle,
          .checkbox {
            background-color: #315667;
          }
        }
      }
    }
    .choice__label {
      p {
        // strips out p-margin from content-p
        margin-bottom: 0;
      }
    }
    .question-preview__multi-input-container {
      margin-top: 8px;
    }
    .question-preview__multi-input {
      &:nth-child(even) {
        background-color: #ebebeb;
      }
      display: flex;
      align-items: center;
      padding: 8px;
    }
  }

  table.matrix-preview,
  .matrix-preview {
    display: block;
    width: 100%;
    overflow-x: auto;
    tr.matrix-row {
      &.matrix-row--alternate-background {
        &:nth-child(even) {
          background-color: #ebebeb;
        }
      }
      td:first-child {
        min-width: 200px;
      }
    }
    .previewitem__choice {
      display: table-row;
      td {
        padding: 8px;
      }
      .matrix-choice {
        &:hover {
          cursor: pointer;
          background-color: #6e9ab7;
          color: white;
        }
      }
    }
    .scale__label {
      padding: 8px;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          // strips out p-margin from content-p°
          margin: 0;
        }
      }
    }
    .choice {
      display: flex;
      justify-content: center;
      align-items: center;
      .circle,
      .checkbox {
        background-color: white;
        border: 1px solid #315667;
        display: block;
        width: 16px;
        height: 16px;
      }
      .circle {
        border-radius: 50%;
      }
      &--active {
        .circle,
        .checkbox {
          background-color: #315667;
        }
      }
    }
  }
}
