@import '~smv-assets/css/variables.scss';

.language-select-dropdown {
  display: flex;
  align-items: center;
  flex-direction: row;
  z-index: 10;
  max-width: 100%;

  .dropdown-menu {
    button {
      font-size: 1rem;
      &:active {
        background-color: white;
        color: $smv-font;
      }
    }
    .custom-switch,
    .custom-control-label {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .dropdown {
    max-width: 100%;
  }
  .dropdown-toggle {
    max-width: 100%;
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .locale-label {
      white-space: nowrap;
    }
  }
}
