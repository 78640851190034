@import '~smv-assets/css/variables.scss';

.smv-row-question-settings__boxes {
  .collapse,
  .collapsing {
    padding: 4px 4px 4px 16px;
    background-color: $smv-background-lighter;
  }
  .smv-row-question-settings__boxes-list-item {
    margin-top: 8px;
    background-color: $smv-background-lighter;
  }
  .smv-row-question-settings__boxes-headline,
  .smv-row-question-settings__boxes-list-item,
  .smv-row-question-settings__boxes-group-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 0 2px 4px;

    &:nth-child(even) {
      background-color: $smv-background-light;
    }
  }
  .smv-row-question-settings__boxes-group-controls {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
