.matrix-preview--carousel {
  .carousel-item-preview {
    min-height: 123px;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
      font-size: 1.5em;
      padding: 15px 25px;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }
  }
  .preview-scale-container {
    display: flex;
    .preview-scale {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .scale-text {
        padding: 4px;
        flex: 1 1 auto;
        display: flex;
        align-items: flex-end;
      }
      // &:nth-child(even) {
      //   background-color: #ebebeb;
      // }
    }
    @media (max-width: 768px) {
      flex-direction: column;
      .preview-scale {
        flex-direction: row-reverse;
        width: 100% !important;
        &:hover {
          background-color: #6e9ab7;
          color: white;
          cursor: pointer;
        }
        .scale-text {
          justify-content: flex-start;
        }
      }
    }
  }
  .previewitem__choice {
    justify-content: center;
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    display: flex !important;
    align-items: center;
    &:hover {
      background-color: #6e9ab7;
    }
  }
  .scale-text {
    display: flex;
    justify-content: center;
    margin-bottom: 0.25rem;
    text-align: center;
    p {
      margin-bottom: 0;
    }
  }
}
