.sharedItem {
  display: flex;
  align-items: center;
  margin-left: 4px;

  > div {
    min-width: 30px;
  }

  .sharedItem__label {
    flex: 1;
  }
}

.shared-choices-list {
  .shared-choices-list__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .shared-choices-list__choices-container {
    max-height: 320px;
    overflow: auto;
  }
}
