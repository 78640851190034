@import '~smv-assets/css/variables.scss';

.active-share-warning {
  background-color: $smv-secondary-light;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  // border: 1px solid $smv-background-lighter;
  color: white;
}
