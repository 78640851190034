@import '~smv-assets/css/variables.scss';

.internal-note {
  display: flex;
  align-items: center;
  border: 1px solid $smv-background-light;
  padding: 0 0 0 0.25rem;
  border-radius: 3px;

  .fa-comment {
    color: $smv-secondary;
  }
}

.internal-note__input {
  font-size: 0.75rem;
  margin-left: 0.5rem;
  border: none;
  height: 28px;
  font-style: italic;
  border-radius: 4px;
}

.internal-note__input:focus,
.internal-note__input:active {
  box-shadow: none;
}
