@import '~smv-assets/css/variables.scss';

.smoove-page-container {
  margin-bottom: 8px;

  .smoove-page-container__group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    border: 1px solid $smv-background-light;
    padding: 4px 4px 4px 8px;
  }

  .que-element-title {
    flex-grow: 1;
  }

  .container-icons {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    align-items: center;
  }

  // .collapse, .collapsing {
  //   width: 100%;
  //   margin-bottom: 0;
  // }

  .smoove-page-container__collapse {
    border-left: 1px solid $smv-background-light;
    padding: 8px 0 0 8px;
    margin-bottom: 0;
  }
}
