@import '~smv-assets/css/variables.scss';

.matrix-scale {
  display: flex;
  align-items: center;

  .fal.qtype {
    font-size: 1rem;
    margin-right: 0.25rem;
  }

  &:hover {
    .ql-editor {
      border: 1px solid $smv-background-light;
    }
  }
}

.matrix-scale-container {
  position: relative;

  .scale-items-container {
    overflow-y: auto;
    max-height: 320px;
    width: 100%;
  }

  &--loading {
    &:after {
      content: 'updating list...';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ebebeb50;
      z-index: 2;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      // opacity: 0.25;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
