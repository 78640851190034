@import 'colors.scss';

@mixin button($colorName, $backgroundColor, $hoverColor, $color) {
  .btn-,
  .btn-smv- {
    &#{$colorName} {
      color: $color;
      background-color: $backgroundColor;
      border: 1px solid $backgroundColor;

      &:hover {
        color: $color;
        background-color: $hoverColor;
        border-color: $hoverColor;
      }

      &.active {
        color: $smv-white;
        background-color: $backgroundColor;
      }

      &:focus {
        box-shadow: none;
      }
      &:disabled {
        color: $smv-background;
        background-color: $smv-white;
        border: 1px solid $smv-background;
      }
    }
  }

  .btn-outline-,
  .btn-smv-outline- {
    &#{$colorName} {
      color: $backgroundColor;
      background-color: $smv-white;
      border: 1px solid $backgroundColor;

      &:hover {
        color: $color;
        background-color: $hoverColor;
        border-color: $hoverColor;
      }

      &.active {
        color: $smv-white;
        background-color: $backgroundColor;
      }

      &:focus {
        box-shadow: none;
      }
      &:disabled {
        color: $smv-background;
        background-color: $smv-white;
        border: 1px solid $smv-background;
      }
    }
  }
}

@each $colorName, $backgroundColor, $hoverColor, $color in $smv-clr-map {
  @include button($colorName, $backgroundColor, $hoverColor, $color);
}
