@import '~smv-assets/css/variables.scss';

.share-report-modal {
  .share-report-modal__shares-table {
    thead td {
      font-weight: bold;
    }
  }

  form.create-share {
    .form-group {
      display: flex;
      align-items: baseline;
    }
  }
  .react-datepicker-wrapper {
    width: auto;
  }
}
