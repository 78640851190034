@import '~smv-assets/css/variables.scss';

.smv-survey-status {
  display: flex;
  flex-direction: column;

  .status-actions {
    display: flex;
    align-items: center;

    .btn-warning {
      color: $smv-white;
    }
  }

  .status-timeline {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 16px 16px 56px 16px;

    .timeline-bubble {
      flex: 0 0 auto;
      position: relative;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      background-color: $smv-background-light;
      display: flex;
      justify-content: center;

      .timeline-bubble__label {
        margin-top: 24px;
        min-width: 80px;
        text-align: center;
      }

      &--active {
        background-color: $smv-secondary;
      }
    }

    .timeline__line {
      display: block;
      width: 100%;
      margin-top: 10px;
      height: 4px;
      background-color: $smv-background-light;

      &--active {
        background-color: $smv-secondary;
      }
    }

    &--draft,
    &--wizard,
    &--testing,
    &--paused {
      .timeline-bubble,
      .timeline__line {
        &--active {
          background-color: $smv-warning;
        }
      }
    }
    &--running {
      .timeline-bubble,
      .timeline__line {
        &--active {
          background-color: $smv-secondary;
        }
      }
    }
    &--complete {
      .timeline-bubble,
      .timeline__line {
        &--active {
          background-color: $smv-primary;
        }
      }
    }
  }

  .status-labels {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .url-input {
    display: inline-block;
    position: relative;
    width: 75%;
    min-width: 300px;

    input {
      text-overflow: ellipsis;
      &:focus {
        text-overflow: ellipsis;
        box-shadow: none;
      }
    }

    @keyframes fadeInOutMoveUp {
      0% {
        opacity: 0;
        transform: translateY(0);
      }
      25% {
        opacity: 1;
        transform: translateY(-10px);
      }
      75% {
        opacity: 1;
        transform: translateY(-10px);
      }
      100% {
        opacity: 0;
        transform: translateY(-20px);
      }
    }

    .copied-notification {
      position: absolute;
      top: -30px;
      right: 0;
      background-color: #28a745;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      font-size: 14px;
      animation: fadeInOutMoveUp 1200ms;
      pointer-events: none;
      z-index: 1000;
    }
  }
}
