@import '~smv-assets/css/variables.scss';

.result-visual {
  position: relative;
  border: 1px solid $smv-background-light;
  overflow: hidden;

  .header-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: top;
  }

  .chart-container {
    height: 400px;
    padding: 0.5rem 0 1rem 0;

    &--loading {
      filter: blur(2px);
    }

    .recharts-legend-wrapper {
      max-height: 180px;
      overflow: auto;
    }
  }
}
