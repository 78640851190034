@import '~smv-assets/css/variables.scss';

.edit-page-buttons {
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  transition: color 250ms ease-in-out;

  &:hover {
    color: $smv-warning;
    i {
      color: $smv-warning;
    }
  }
}
