@import '~smv-assets/css/variables.scss';

.administration-page {
  .client-list {
    .client-row {
      .fa-trash-alt {
        color: $smv-danger;
        cursor: pointer;
      }
    }
  }
  .basic-search-input__input {
    min-width: 300px;
    margin-bottom: 0.5rem;
  }
}
