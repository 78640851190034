@import '~smv-assets/css/variables.scss';

.survey-list {
  .project-settings-link {
    color: $smv-secondary;
    min-width: 200px;
    text-align: right;

    &:hover {
      cursor: pointer;
      color: $smv-secondary-dark;
      span {
        text-decoration: underline;
      }
    }
  }

  .survey-list-container {
    display: flex;
    flex-direction: column;

    .survey-list-element__title {
      font-size: 1.125rem;
      font-weight: light;
    }

    .survey-list-element-row {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid $smv-background-light;
      background-color: $smv-white;

      .survey-list-element-row__left {
        padding: 0.5rem 0 0.5rem 0.5rem;
        flex: 1 1 auto;
      }
      .survey-list-element-row__right {
        padding: 0.5rem 0.5rem 0.5rem 0;
        .fa-cog:hover {
          color: $smv-secondary;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $smv-background-light;
      }

      &--new {
        padding: 4px 0;
        transition: background-color ease-in-out 150ms, color ease-in-out 150ms;
        .survey-list-element__title {
          display: flex;
          align-items: baseline;
        }
        &:hover {
          background-color: $smv-primary-light;
          color: $smv-white;
        }
      }
    }

    .survey-list-element-tile {
      display: flex;
      flex-direction: column;
      border: 1px solid $smv-background-light;
      transition: border-color 150ms ease-in-out;

      .tile-image {
        position: relative;
        max-height: 250px;
        display: flex;
        overflow: hidden;
        background-color: $smv-white;

        img {
          width: 100%;
          aspect-ratio: 1/1;
          object-fit: contain;
          object-position: center;
          transition: transform 150ms ease-in-out;
        }
        .new-survey-img-placeholder {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          aspect-ratio: 1/1;
          background-color: $smv-background-light;
          transition: background-color ease-in-out 150ms, color ease-in-out 150ms;

          .fal {
            transition: transform ease-in-out 150ms;
          }
          &:hover {
            background-color: $smv-primary-light;
            color: $smv-white;
            .fal {
              transform: scale(1.4);
            }
          }
        }
      }
      .tile-description {
        background-color: $smv-white;
        padding: 8px;
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-top: 1px solid $smv-background-light;

        .survey-list-element__title {
          flex: 1 1 auto;

          &--new {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
        }
      }

      &:hover {
        border-color: $smv-primary;
        .tile-image img {
          transform: scale(1.1);
        }
      }
    }
  }

  .survey-list-container--tiles {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    margin-top: 24px;

    @media (min-width: $breakpoint-sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media (min-width: $breakpoint-md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (min-width: $breakpoint-xl) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }
}
