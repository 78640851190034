@import '~smv-assets/css/variables.scss';

.smv-sidebar-toggle-btn-mobile {
  width: 100%;
  background-color: $smv-background;
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $smv-background-light;
  }
}
