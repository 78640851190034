@import '~smv-assets/css/variables.scss';

.smv-calculation {
  border: 1px solid $smv-background-light;
  margin-bottom: 1rem;
  padding: 8px;
  position: relative;

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 0;
    width: 100%;
    opacity: 0.2;
    border-radius: 3px;

    background-color: transparent;
    transition: background-color 500ms, height 0ms 500ms;
  }

  &.smv-scroll-indicator {
    &:after {
      z-index: 2;
      height: 100%;
      background-color: $smv-primary;
      transition: background-color 250ms;
    }
  }

  .smv-calculation__title {
    input {
      min-width: 200px;
    }
  }

  .fas {
    font-size: 0.88rem;
  }

  .form-control {
    font-size: 0.75rem;
  }

  .smv-calculation-label {
    min-width: 40px;
  }

  .smv-calculation-value {
    font-size: 0.75rem;
    padding: 8px;
    margin-bottom: 8px;
    background-color: $smv-background-lighter;
    border: none;
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin: 8px auto;

    .smv-calculation__title {
      max-width: 92%;
      margin-bottom: 0;

      input {
        max-width: 100%;
        min-width: 70px;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        border: 1px solid $smv-background-light;
        border-radius: 0.125rem;
      }
    }
  }
}
