@import '~smv-assets/css/variables.scss';

.sidebyside-translation-modal {
  .sidebyside-translation-modal__settings {
    position: sticky;
    top: 0;
    background-color: white;
    padding-top: 8px;
    padding-bottom: 8px;
    z-index: 5;
    border-bottom: 1px dotted $smv-background-light;
  }
  .translation-content-container {
    border-right: 1px solid $smv-background-light;
    border-left: 1px solid $smv-background-light;
    border-bottom: 1px solid $smv-background-light;
  }

  .jumpto-dropdown {
    .dropdown-menu {
      max-height: 320px;
      overflow-y: auto;
    }
  }

  .translation-table {
    table-layout: fixed;
    margin-bottom: 16px;
    margin-top: 16px;
    border: 2px solid $smv-background-light;
    td {
      // border: 1px solid #ebebeb;
      padding: 0;
    }
    .translate-shared {
      background-color: $smv-background-lighter;
      color: $smv-background-dark;
      &:hover {
        cursor: pointer;
      }
      .ql-editor > * {
        cursor: pointer;
      }
    }
    .translation-question-title {
      background: $smv-background-light;
      padding: 2px 0;
      h6 {
        margin: 0px 4px !important;
      }
      input {
        background: $smv-background-light;
      }
      &--big {
        padding: 4px 0;
      }
    }
  }

  .smv-scroll-indicator {
    z-index: 2;
    height: calc(100% + 10px);
    background-color: $smv-primary-light;
    transition: background-color 250ms;
  }

  .jodit-react-container {
    font-size: 0.8rem;
  }

  .ai-translation-floating-button {
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 2000;
    .ai-translation-button {
      position: fixed;
    }
  }

  .ai-translation-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $smv-ai-gradient;
    color: white;
    font-size: 1rem;
    padding: 8px 8px;
    border-radius: 40px;
    border: none;
    cursor: pointer;
    text-transform: uppercase;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    transition: box-shadow 0.3s ease;
    bottom: 16px;
    font-size: 0.75rem;

    &--white {
      background: white;
      color: $smv-secondary;
      box-shadow: none;
    }

    &:hover {
      box-shadow: 0 4px 16px rgba(128, 0, 255, 0.8);
    }
  }

  .ai-translation-button--flags {
    padding: 8px 12px;

    .fal {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 4px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }

    .flag-icon {
      font-size: 1.25rem;
    }
  }

  .ai-translation-button--translate-all {
    padding: 12px 24px 12px 12px;
    font-size: 1rem;

    .fal {
      background-color: rgba(255, 255, 255, 0.1);
      padding: 10px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
    }
  }
}
