@import '~smv-assets/css/variables.scss';

.smv-export-file-modal {
  .modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .export-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-bottom: 1rem;

      .export-list__item {
        border: 2px solid $smv-background-light;
        border-radius: 2px;
        padding: 1rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
        width: 128px;
        height: 128px;
        text-align: center;
        flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: space-around;

        &:hover {
          background-color: $smv-background-lighter;
          cursor: pointer;
        }

        &--active {
          background-color: $smv-background-lighter;
          border: 2px solid $smv-primary;
        }
      }
    }

    .btn-export-options {
      i {
        margin-right: 0.3rem;
      }
    }
  }
}
