@import '~smv-assets/css/variables.scss';

.choiceItem {
  display: flex;
  align-items: center;
  padding: 4px;
  min-width: 260px;

  > div {
    width: 30px;
  }

  .choiceItem__grab {
    cursor: grab;
    text-align: center;
  }

  .choiceItem__label {
    flex: 1;
    background-color: white;
    border-radius: 0.25rem;
  }

  .choiceItem__value {
    flex-basis: 80px;
    &--warning {
      input {
        border: 1px solid $smv-warning;
      }
    }
    .choiceitem-input {
      &--invalid {
        border: 1px solid $smv-danger;
      }
    }
    .choiceitem-danger {
      color: $smv-danger;
    }
  }

  .choiceItem__pin {
    text-align: center;
    .choiceItem__icon--disabled {
      color: $smv-background;
    }
  }
  .choiceItem__trash {
    text-align: center;
  }

  .choiceItem__shared--excluded {
    font-style: italic;
    color: $smv-background;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgba(255, 255, 255, 0);
    }
  }
}

.choiceItem--new {
  background-color: $smv-background-light;
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  padding: 8px;
  > div {
    width: auto;
  }

  .choiceItem__label .ql-editor {
    padding-left: 8px !important;
  }
}
