@import '~smv-assets/css/variables.scss';

.smv-custom-dropdown__dropdown-menu {
  min-width: 9rem;
  padding: 2px;
  border-radius: 0.25rem;

  &--nested {
    display: flex;
    flex-direction: column;
  }

  .dropdown-divider {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }

  .dropdown-item {
    padding: 0;
    margin: 0;

    .smv-custom-dropdown__buttons {
      display: flex;
      font-size: 0.75rem;
      align-items: center;
      justify-content: flex-start;
      font-weight: normal;
      transition: color 250ms ease-in-out;
      cursor: pointer;

      margin: 0;
      padding: 0.5rem;

      i {
        color: $smv-secondary;
        transition: color 250ms ease-in-out;
        min-width: 14px;
      }

      span {
        white-space: nowrap;
      }
    }

    .active,
    &--active,
    &:active,
    &:hover {
      background-color: inherit;
      color: $smv-warning;
      i {
        color: $smv-warning !important;
      }
    }

    &--nested {
      display: flex;
      flex-direction: column;
    }
  }
}

.smv-custom-dropdown__dropdown-toggle {
  cursor: pointer;
  .fa-ellipsis-v {
    padding: 0 8px;
  }

  &--flex {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between !important;
  }
}
