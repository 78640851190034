/* variables */
@import '~smv-assets/css/variables.scss';

.questionaire-sidebar__structure {
  font-size: 0.75rem;

  .node {
    display: flex;
    align-items: center;
    height: 34px;
    cursor: pointer;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-left: 10px;

    &.structure-level-0 {
      padding-left: 10px;
      border-left: none;
      margin-left: 0px;
    }

    &:hover {
      background-color: $smv-secondary-light;
      color: white;
    }

    &.isOver.canDrop {
      &.drop-above {
        border-top: 3px solid $smv-secondary;
        padding-top: 0px;
      }
      &.drop-inside {
        background-color: $smv-droparea;
        color: $smv-white;
      }
      &.drop-below {
        border-bottom: 3px solid $smv-secondary;
        padding-bottom: 0px;
      }
    }
  }

  .questionnaire-sidebar__text-container {
    display: flex;
    align-items: center;
    width: calc(100% - 18px);
    padding-right: 4px;
  }

  .node-title {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-left: 2px;

    &.placeholder {
      font-style: italic;
      opacity: 0.8;
    }
  }

  .node-icon {
    min-width: 18px;
    text-align: center;
    img {
      width: 12px;
    }
  }

  .node-collapse-icon {
    width: 10px;
    text-align: center;
    margin-right: 8px;
  }

  .node-collapse-icon i {
    transition: 0.1s linear;
  }
  .node-collapse-icon .expanded {
    transform: rotate(90deg);
  }

  .node-collapse-icon:hover {
    cursor: pointer;
  }

  .node-child {
    padding-left: 18px;
  }

  .collapse,
  .collapsing {
    border-left: 1px solid $smv-background-light;
    margin-left: 15px;
  }

  /* Drag and drop styles */
  /* .draggable-ghost {
  border: 1px solid #bbbbbb;
  border-left: 2px solid #8939ad;
  border-radius: 3px 0 0 3px !important;
  background-color: white;
  box-shadow: 0 3px 4px rgba(116, 116, 116, 0.3) !important;
  padding: 5px;
} */

  .isDragging {
    opacity: 0.4 !important;
  }

  .droppable.draggable-match {
    border: 0;
  }
  .droppable.draggable-over {
    border: 0;
  }

  .draggable-over {
    position: absolute;
    width: 100%;
    line-height: 1px;
    margin-top: -4px;
  }

  .isOver .draggable-over .drop-location-circle {
    display: inline-block;
    width: 6px;
    height: 6px;
    border: 4px solid $smv-droparea;
    border-radius: 50%;
  }

  .isOver .draggable-over .drop-location-line {
    display: inline-block;
    border-bottom: 2px solid $smv-droparea;
    margin-bottom: 3px;
    width: 75%;
    line-height: 8px;
  }
}
