@import '~smv-assets/css/variables.scss';

.smv-sidebar-toggle-btn {
  width: 100%;
  background-color: $smv-background;
  padding: 8px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: $smv-background-light;
  }
}

.smv-sidebar-left-toggle-open,
.smv-sidebar-right-toggle-open {
  display: none;
  width: 100%;
  @media (min-width: $breakpoint-md) {
    display: block;
    position: sticky;
    top: 0;
    z-index: 6;
  }
}
