@import '~smv-assets/css/variables.scss';

$sig-high-color-chart: #008000;
$sig-low-color-chart: #ff0000;
$sig-mutually-color-chart: #ac4bbd;

$sig-high-color: #c5e1a5;
$sig-low-color: #ffcdd2;
$sig-mutually-color: #e1bee7;

.smv-dashboard-element__footer {
  position: absolute;
  bottom: 0;
  background-color: white;
  z-index: 2;
  width: 100%;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  .ql-editor {
    padding: 0;
  }

  .dashboard-footer-toggle-btn {
    position: absolute;
    top: 7px;
    left: 15px;
    &:hover {
      cursor: pointer;
    }
    .fa-chevron-down {
      transition: transform 200ms ease-in-out;
      &--rotate {
        transform: rotate(-180deg);
      }
    }
  }
  .dashboard-footer-text-container {
    min-height: 22px;
    max-height: 22px;
    overflow: hidden;
    transition: max-height 220ms ease-in-out;

    &--open {
      max-height: 300px;
      overflow-y: auto;
      .dashboard-footer-text .footer__question-text,
      .dashboard-footer-text .footer__bases {
        flex-wrap: wrap;
        white-space: unset;
      }
    }
  }

  .dashboard-footer-text {
    display: flex;
    flex-direction: column;
    width: 100%;
    // width: calc(100% - 1.5rem);

    .footer-text {
      display: flex;
      flex-wrap: nowrap;
      font-size: 0.75rem;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 100%;
      margin-bottom: 2px;
      margin-top: 2px;
    }

    .footer__question-text {
      overflow-x: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 80%;
      display: block;
      padding-right: 8px;
    }

    .footer__bases {
      max-width: 100%;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sig__container {
    display: flex;
    align-items: center;
  }

  .sig__icon {
    display: inline-block;
    padding: 0.1rem 4px;
    width: 0.8rem;
    height: 0.8rem;
    line-height: 1rem;

    &__circle {
      border-radius: 6px;
    }

    &.sig__icon__chart--mutually {
      background-color: #fff;
      border: 2px solid $sig-mutually-color-chart;
    }

    &.sig__icon__chart--high {
      background-color: #fff;
      border: 2px solid $sig-high-color-chart;
    }

    &.sig__icon__chart--low {
      background-color: #fff;
      border: 2px solid $sig-low-color-chart;
    }

    &__table--mutually {
      background-color: $sig-mutually-color;
    }

    &__table--high {
      background-color: $sig-high-color;
    }

    &__table--low {
      background-color: $sig-low-color;
    }
  }
}
