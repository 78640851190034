.help-page {
  .card {
    overflow: hidden;
  }
  a {
    img {
      transition: transform ease-in-out 200ms;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
}
