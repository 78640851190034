@import '~smv-assets/css/variables.scss';

.result-config {
  padding: 0.75rem 0.5rem;
  border-top: 1px solid $smv-background-light !important;

  .fa-chevron-up,
  .fa-chevron-down {
    font-size: 0.75rem;
  }

  .list-group-item {
    padding: 0.5rem;

    border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
    margin-top: 10px;
  }
  select {
    font-size: 0.75rem;
  }
  input[type='text'],
  input[type='number'] {
    width: 100%;
    font-size: 0.75rem;
    min-height: 32px;
    color: $smv-font;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 0.375rem 0.125rem 0.375rem 0.75rem;
  }
  input:disabled {
    background: #e9ecef;
    opacity: 0.7;
  }
  input[type='checkbox'],
  input[type='switch'] {
    font-size: 1rem;
  }
}
