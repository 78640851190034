@import '~smv-assets/css/variables.scss';
.changelog-modal-container {
  .modal-header {
    padding: 8px 24px 0 24px;
    .modal-title {
      max-width: 100%;
    }
  }

  .changelog-modal-navigation-container {
    position: relative;
    z-index: 2;

    .changelog-modal-navigation-container__btn-previous,
    .changelog-modal-navigation-container__btn-next {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: white;
      transition: background-color 0.3s, color 0.3s, border-color 0.3s;
      border: 1px solid $smv-background;
      cursor: pointer;

      &:hover {
        border-color: $smv-secondary-light;
        background-color: $smv-secondary-light;
        color: white;
      }
    }

    .changelog-modal-navigation-container__btn-previous {
      left: 20px;

      &--disabled {
        display: none;
      }
    }

    .changelog-modal-navigation-container__btn-next {
      right: 20px;

      &--disabled {
        display: none;
      }
    }
  }
}
