@import '~smv-assets/css/variables.scss';

.custom-node {
  display: flex;
  align-items: center;
  cursor: grab;

  &--is-drop-target {
    background-color: $smv-background-light;
  }
  &--is-drop-target:not(.custom-node--is-open) {
    background-color: $smv-secondary-light;
    color: white;
  }
  &--readonly {
    cursor: default;
  }
}

.table-folder {
  padding: 6px 0px 6px 0px;
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-basis: 100%;
  border-radius: 0 !important;
  transition: background-color ease-in-out 200ms;
  max-width: calc(100% - 12px);
  white-space: nowrap;
  cursor: pointer;

  &--drop-target {
    background-color: $smv-background-light;
  }

  .table-folder__label {
    flex-grow: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-x: hidden;
  }

  .table-folder__headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .table-folder__headline-text {
      flex: 2 1 auto;
      font-size: 0.75rem;
      overflow-x: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-folder__empty-indicator {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid $smv-background-light;
  }
}

.table-item {
  flex-basis: 100%;
  padding: 6px 0px 6px 4px;
  margin-left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  transition: background-color ease-in-out 200ms;
  a {
    text-decoration: none;
    &:hover {
      .table-item__label {
        text-decoration: underline;
      }
    }
  }

  &--sortable,
  &--draggable {
    &:hover {
      cursor: grab;
    }
  }

  &--drop-target {
    background-color: $smv-background-light;
  }

  .table-item__link {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
    width: 100%;
    display: flex;
    align-items: center;
  }

  .table-item__label {
    padding-left: 4px;
    color: $smv-font-dark;
    padding-right: 4px;
    text-overflow: ellipsis;
    width: 100%;
    transition: color 200ms ease-in-out;
    overflow: hidden;
  }

  .table-item__chart-icon {
    color: $smv-secondary;
    transition: color 200ms ease-in-out;
  }

  &--active {
    background-color: $smv-secondary-light;

    .table-item__label {
      color: $smv-white;
    }
    .table-item__chart-icon,
    .smv-custom-dropdown__dropdown-toggle {
      color: $smv-white;
    }
  }
}
