@import '~smv-assets/css/variables.scss';

.projects {
  .projects__column-left {
    border-right: 1px solid $smv-background;
    padding-right: 0;
    min-height: 250px;
  }
  .projects__head {
    margin-right: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-left: 8px;
    // border-bottom: 1px solid $smv-background-light;
    @media (min-width: $breakpoint-md) {
      flex-direction: column-reverse;
    }
    @media (min-width: $breakpoint-lg) {
      flex-direction: row;
    }
  }

  .projects__column-left {
    .basic-search-input {
      width: 100%;
      padding: 4px;
    }
  }

  .project-list {
    padding-right: 15px;
    margin-top: 0.5rem;
    max-height: 64vh;
    min-height: 100px;
    overflow-y: auto;
  }

  .project-list-element {
    cursor: pointer;
    display: flex;
    // justify-content: ;
    align-items: center;
    border: 1px solid $smv-background-lighter;
    border-radius: 0.25rem;

    &:hover {
      border-color: $smv-background-light;
      .project-list-element__settings-button,
      .project-list-element__readonly-icon {
        opacity: 1;
      }
    }

    .project-list-element__title {
      font-weight: normal;
      // font-size: 1.125rem;
      display: flex;
      align-items: center;
      padding-right: 8px;
    }

    .project-list-element__settings-button,
    .project-list-element__readonly-icon {
      opacity: 0;
      transition: ease-in-out 300ms;
    }

    &--selected {
      background-color: $smv-background-light;
      border: 1px solid $smv-background-light;

      .project-list-element__title {
        font-weight: bold;
      }

      .project-list-element__settings-button,
      .project-list-element__readonly-icon {
        opacity: 1;
      }
    }
  }
}
