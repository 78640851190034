@import '~smv-assets/css/variables.scss';

.smv-panel-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // margin-bottom: 1rem;

  .smv-panel-list__item {
    cursor: pointer;
    display: flex;
    width: 140px;
    height: 120px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem 1rem 0;
    position: relative;
    border-radius: 0.25rem;
    border: 2px solid $smv-background-light;
    background-color: $smv-background-lighter;

    &:hover {
      .smv-panel-list__item-label {
        background-color: $smv-background-light;
      }
    }

    &--selected {
      border: 2px solid $smv-primary;

      .smv-panel-list__item-label {
        background-color: $smv-background-light;
        border: none;
      }
    }
  }

  .smv-panel-list__item-image {
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
    margin: 8px;
    img {
      object-fit: contain;
      object-position: center center;
      display: block;
      position: absolute;
      width: 90%;
      max-height: 65px;
    }
  }

  .smv-panel-list__item-label {
    width: 100%;
    text-align: center;
    padding: 4px;
    min-height: 20px;
    transition: background-color 200ms ease-in-out;
    background-color: $smv-white;
  }
}
