@import '~smv-assets/css/variables.scss';

.table-tree__placeholder {
  background-color: $smv-secondary;
  height: 2px;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-50%);
}
