@import './colors.scss';

$body-color: $smv-font;

$primary: $smv-primary;
$secondary: $smv-secondary;
$grey: $smv-font-light;
$warning: $smv-warning;
$danger: $smv-danger;

$theme-colors: (
  'primary': $primary,
  'secondary': $secondary,
  'grey': $grey,
  'warning': $warning,
  'danger': $danger
);

@import '~bootstrap/scss/bootstrap';
// re-add utility classes for paddings and margins as they were renamed in bootstrap 5 but we prefer the old naming conventions. Now we can use both
@import './bootstrapv4utilities.scss';

// overwrite newly added default colors for .btn-grey
.btn-grey,
.btn-grey:hover {
  color: white;
}

// overwrite bootstrap default variables
a {
  text-decoration: none;
  color: $smv-secondary;
  transition: color ease-in-out 200ms;

  &:hover {
    color: $smv-secondary-dark;
  }
}

.table {
  --bs-table-color: #{$smv-font};
  --bs-table-striped-color: #{$smv-font};
  --bs-table-hover-color: #{$smv-font};
  --bs-table-striped-hover-color: #{$smv-font};
  --bs-table-bg: transparent;
}

.card {
  --bs-card-border-radius: 0.25rem;
}

.navbar {
  // --bs-navbar-color: #{$smv-font};
  --bs-navbar-active-color: #{$smv-font-dark};
  .nav-link {
    --bs-nav-link-color: #{$smv-font-light};
    --bs-nav-link-hover-color: #{$smv-font};
  }
}

.nav-tabs {
  .nav-link {
    --bs-nav-link-color: #{$smv-secondary};
    --bs-nav-link-hover-color: #{$smv-secondary-dark};
  }
}
