@import '~smv-assets/css/variables.scss';

.smv-result-table--chart {
  height: 100%;
  max-height: 100%;

  table {
    table-layout: fixed;

    th {
      word-wrap: break-word;
      word-break: break-all;
    }
  }

  #header__spare-1_question-title {
    width: 350px;
  }

  #columnhead__spare-1__matrix_choice_title {
    width: 200px;
  }

  #header__spare-1 {
    width: 200px;
  }

  #header__spare-2 {
    width: 150px;
  }

  #columnhead__spare-1 {
    width: 150px;
  }

  #columnhead__spare-2 {
    width: 200px;
  }

  .columnhead__base {
    width: 50px;
  }

  #columnhead__total {
    width: 50px;
  }

  .row__header {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
