.custom-drag-preview {
  align-items: 'center';
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 12px 24px -6px rgba(0, 0, 0, 0.25), 0 0 0 1px rgba(0, 0, 0, 0.08);
  display: inline-grid;
  font-size: 14px;
  gap: 8px;
  grid-template-columns: auto auto;
  padding: 4px 8px;
  pointer-events: none;
}

.custom-drag-preview__label {
  display: flex;
  padding-left: 8px;
  align-items: center;
}
