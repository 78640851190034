@import '~smv-assets/css/variables.scss';

.wordcloud-config {
  .blacklist-short {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.wordcloud-settings-modal {
  .blacklist-table-container {
    overflow: auto;
    max-height: 350px;
  }

  .blacklist-table {
    border-collapse: separate;
    border-spacing: 0;

    .trash-btn {
      vertical-align: middle;
    }
  }
  .sticky-table-head {
    position: sticky;
    top: -1px;
    background-color: $smv-white;

    + .blacklist-table-content {
      padding-top: 102px;
    }
  }
}
