.config-tab {
  h6 {
    margin-bottom: 2px;
    font-weight: normal;
    font-size: 14px;
  }
  .config-group {
    margin-bottom: 1.5rem;
  }
  .modal-body {
    padding-top: 0;
  }
  .question-type {
    display: inline-block;
    margin-top: 8px;
  }
}
