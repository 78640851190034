@import '~smv-assets/css/variables.scss';

.active-filter-list {
  // background: $smv-background-lighter;
  // border-left: 1px solid $smv-background-light;
  // border-right: 1px solid $smv-background-light;
  // border-top: 1px solid $smv-background-light;
  // margin-top: -1px;
  padding: 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.75rem;

  .active-filter-list__group {
    display: flex;
    background-color: $smv-white;
    padding: 0.125rem 0.25rem;
    border: 1px solid $smv-background-light;
    border-radius: 0.25rem;
    margin-left: 0.25rem;
    align-items: center;

    &--label {
      font-weight: bold;
    }

    .active-filter-list__element {
      margin: 0.125rem 0.25rem;
      cursor: default;
      transition: color ease-in-out 100ms;

      &.deselectable:hover {
        cursor: pointer;
        color: $smv-warning;
        .active-filter-list__remove-filter {
          display: inline-block;
        }
      }

      .active-filter-list__remove-filter {
        display: none;
        margin-left: 0.25rem;
        color: $smv-warning;
      }
    }
  }
}
