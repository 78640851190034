@import '~smv-assets/css/variables.scss';

.login-page {
  .login-form {
    margin-top: 1rem;
    padding-top: 1rem;
  }

  @media (min-width: $breakpoint-md) {
    .login-page__container {
      margin-top: 3rem;
    }
  }
}
