@import '~smv-assets/css/variables.scss';

.active-indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  top: -4px;
  right: -4px;
  z-index: 10;
  background-color: $smv-primary;
}
