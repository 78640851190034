@import '~smv-assets/css/variables.scss';

.question-item {
  margin-left: 10px;
  width: calc(100% - 10px);
  padding: 8px 0;
  border-bottom: 1px solid $smv-background-light;
  display: flex;

  img {
    width: 16px;
  }

  &:hover {
    cursor: grab;
  }

  &:last-of-type {
    border-bottom: 0px;
  }
}
