// @import '~smv-assets/css/variables.scss';

.result-visual .chart-container,
.smv-result-report-element .card-body {
  .result-table-container {
    // max-height: calc(100% - 50px);
    max-height: 100%;
    padding-right: 5px;
  }
  .result-table-listing-footer {
    display: flex;
  }
}
