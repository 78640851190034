@import '~smv-assets/css/variables.scss';

.smv-dashboard-element {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 0.25rem;

  .card-header {
    // min-height: 50px;
    padding: 0.75rem 1.25rem;

    &:hover {
      cursor: move;
    }
  }

  .card-title {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .card-subtitle {
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .card-body {
    padding-bottom: 52px;
    min-height: 1px;
    // &--significances {
    // padding-bottom: 72px;
    // }
  }

  .card-header,
  .card-footer {
    background-color: $smv-white;
  }

  &--readonly {
    .card-header {
      &:hover {
        cursor: default;
      }
    }
  }

  .card-body {
    position: relative;

    z-index: 2;
    .recharts-legend-wrapper {
      z-index: 0;
    }
    .recharts-surface {
      z-index: 1;
    }
    .recharts-surface {
      z-index: 3;
    }
  }

  .smv-dashboard-element__loading-backdrop {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #ffffffa9;
    color: $smv-white;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 3rem;

    i.fa-spinner {
      color: #858585;
    }
  }
}

@keyframes chart-skeleton {
  0% {
    background-color: rgb(209, 209, 209);
  }
  100% {
    background-color: rgb(243, 243, 243);
  }
}

.chart-skeleton {
  border: 1px solid $smv-background-light;
  background-color: $smv-white;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  .line {
    min-height: 8px;
    margin: 0.5rem 0;
    width: 100%;
    border-radius: 0.3rem;
    background-color: rgb(209, 209, 209);
    animation: chart-skeleton 1s ease-in-out infinite alternate;
  }
  .chart-header {
    width: 100%;
    padding: 8px 16px;
    border-bottom: 1px solid $smv-background-light;
    .line {
      height: 16px;
      width: 64%;
    }
  }

  .lines {
    width: 100%;
    padding: 16px;
    flex: 2 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .line:nth-child(1) {
      width: 75%;
    }
    .line:nth-child(2) {
      width: 50%;
    }
    .line:nth-child(3) {
      width: 88%;
    }
    .line:nth-child(4) {
      width: 32%;
    }
    .line:nth-child(5) {
      width: 64%;
    }
  }
  .chart-footer {
    width: 100%;
    padding: 0px 16px;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $smv-background-light;
    .line {
      height: 12px;
    }
    .line:nth-child(1) {
      width: 10%;
    }
    .line:nth-child(2) {
      width: 24%;
    }
  }
}
