@import '~smv-assets/css/variables.scss';

// multipleselect - Multiple Select Dropdown
.multipleselect__selected-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.multipleselect__selected-container--empty {
  border-radius: 0.25rem;
}

.multipleselect__selected-item {
  display: flex;
  margin: 2px;
}

.multipleselect__selected-item-label {
  color: $smv-secondary;
  max-width: 60px;
  min-width: 40px;
  padding: 0px 3px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 1px solid $smv-secondary;
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-left: 0;
  background-color: transparent;

  &:hover {
    color: white;
    background-color: $smv-secondary;
    border-color: $smv-secondary;
    cursor: pointer;
  }
}

.multipleselect__selected-item-delete {
  color: $smv-secondary;
  padding: 0 4px;
  border: 1px solid $smv-secondary;
  border-right: 0px;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  background-color: transparent;

  &:hover {
    color: white;
    background-color: $smv-secondary;
    border-color: $smv-secondary;
    cursor: pointer;
  }
}

.multipleselect__additional-items {
  color: $smv-secondary;
  padding: 0 4px;
  border: 1px solid $smv-secondary;
  border-radius: 0.25rem;
  background-color: transparent;
  display: flex;
  margin: 2px;

  &:hover {
    color: white;
    background-color: $smv-secondary;
    border-color: $smv-secondary;
    cursor: pointer;
  }
}

.dropdownitem {
  padding: 0;
}

.multipleselect__selected-item--disabled {
  .multipleselect__selected-item-label {
    border: 1px solid $smv-secondary;
    border-radius: 0.25rem;
    padding-left: 6px;
  }
}
