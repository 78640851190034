.ranking-matrix-preview {
  &--drag {
    display: flex;
  }

  .ranking-droppable-unselected {
    background-color: white;
  }

  .ranking-matrix-dnd-container {
    display: flex;
    width: 100%;

    .ranking-matrix-dnd-container__droppable-column {
      width: 40%;
    }

    .ranking-matrix-dnd-container__arrow-column {
      width: 20%;
    }
  }
}

.matrix-ranking-preview__item {
  cursor: pointer;
  display: flex;
  align-items: center;

  .choice__rank {
    width: 40px;
    height: 40px;
    flex: 0 0 auto;
    text-align: center;
    background-color: #315667;
    margin: 0.5rem;
    color: white;
    padding: 0.5rem;
  }

  .choice__label {
    flex: 1 1 auto;
    background-color: #6e9ab7;
    margin: 0.5rem;
    color: white;
    padding: 0.5rem;
  }

  &--selected {
    .choice__label {
      background-color: #315667;
    }
  }

  &--indent-right {
    flex-direction: row-reverse;
  }
}
