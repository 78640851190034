@import '~smv-assets/css/variables.scss';

.smv-stone-container {
  display: none;
  @media (min-width: $breakpoint-md) {
    display: block;
  }
}

.stone-smv {
  fill: $smv-primary;
}

@mixin stoneFillColor($colorName, $color, $hoverColor) {
  .stone-smv-- {
    &#{$colorName} {
      fill: $color;
    }
  }
}

@each $colorName, $color, $hoverColor in $smv-clr-map {
  @include stoneFillColor($colorName, $color, $hoverColor);
}
