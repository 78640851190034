@import '~smv-assets/css/variables.scss';

.tabulation-assistant-modal {
  .loading-bar-container {
    display: flex;
    align-items: center;

    .tabulation-loading-progress-bar {
      margin-left: 1rem;
      max-width: 240px;
    }
  }
}
