.selected-filter-list .node {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 34px;
  padding: 9px 0 9px 9px;
  user-select: 'none';

  .node__title {
    flex-grow: 1;
    margin-left: 10px;
  }
}
