.editabletext .ql-tooltip {
  width: 200px;
}

.editabletext {
  min-width: 120px;
  flex-grow: 1;
  cursor: text;
  min-height: 26px;
  .ql-editor {
    min-height: 24px;
  }
}
