@import '~smv-assets/css/variables.scss';

.smv-client-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  display: block;
  text-transform: none;
}
