@import '~smv-assets/css/variables.scss';

.dashboard-element--text {
  .card-body {
    overflow-y: auto;
    padding-bottom: 20px !important;

    // > div {
    //   width: 100%;
    //   height: 100%;
    // }

    &--no-editmode {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  .edit-toolbar {
    &--non-edit {
      position: absolute;
      right: 15px;
      top: 15px;
    }
  }

  .edit-text-btn {
    background-color: white;
    opacity: 0;
    transition: opacity ease-in-out 220ms;

    &:hover {
      background-color: $smv-secondary;
    }
  }

  &:hover {
    .edit-text-btn {
      opacity: 1;
    }
  }
}

.dashboard-text-modal {
  .dashboard-text-modal__jodit-container {
    border-radius: 3px 3px 0 0;
    border: 1px solid $smv-background;

    .jodit-container:not(.jodit_inline) {
      border: none !important;
    }
    .jodit-toolbar__box:not(:empty) {
      border-radius: 0;
    }
    .jodit-status-bar,
    .jodit-add-new-line {
      display: none;
    }
  }
}
