@import '~smv-assets/css/variables.scss';

.survey-country-table {
  .survey-country-row {
    background-color: white;
    width: 100% !important;
  }
}

.locales-list__unselected {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid $smv-background-light;
  border-radius: 4px;

  .locales-list__unselected-search {
    padding: 8px 16px 4px 16px;
  }
  .locales-list__unselected-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    max-height: 220px;
    padding: 8px 16px;
  }
  .locales-list__add-locale-btn {
    border: 1px solid $smv-secondary;
    border-radius: 2px;
    font-size: 0.75rem;
    padding: 2px;

    cursor: pointer;
    &:hover {
      background-color: $smv-secondary;
      color: white;
    }
  }
  .locales-list__unselected-locale {
    display: flex;
    align-items: center;
    cursor: pointer;
    &:hover {
      .locales-list__add-locale-btn {
        background-color: $smv-secondary;
        color: white;
      }
    }
  }
}
