@import '~smv-assets/css/variables.scss';
@import '~smv-assets/css/mixins';

.reports-page {
  font-size: 0.75rem;

  h2 {
    font-size: 1.75rem;
    line-height: 1;
  }

  h5 {
    font-size: 1rem;
  }

  .reports-head-container {
    position: sticky;
    z-index: 4;
    top: 60px;
    background-color: white;
    padding-top: 20px;
    margin-top: -20px;
    max-width: none;
    margin-right: -30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 8px;

    .reports-head-container__headline {
      min-width: 0;
      flex-grow: 2;
    }

    h2 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-bottom: 0;
      line-height: 1;
    }
  }

  .reports-sidebar-left {
    @include scrollbar-small;

    min-width: 100px;
    position: sticky;
    top: 119px;
    // use mixin for the 198px as its calculated by the height of headline and padding of base-content?
    height: calc(100vh - 198px);
    padding-right: 0px;
    margin-right: 15px;
    transition: max-height ease-in-out 200ms, max-width ease-in-out 100ms;

    // @media(min-width: $breakpoint-md) {
    // position: sticky;
    // top: 88px;
    // max-height: 80vh;
    // }

    .list-group--first-lvl {
      border-radius: 0;
      height: calc(100% - 28px);
      overflow-y: auto;
      padding-bottom: 0;
      margin-top: -1px;
    }

    .fa-chevron-up,
    .fa-chevron-right,
    .fa-chevron-down {
      font-size: 0.75rem;
      min-width: 12px;
      display: flex;
      justify-content: center;
    }
  }

  .reports-page__report-container {
    padding: 0;
  }
}
