@import '~smv-assets/css/variables.scss';
@import '~smv-assets/css/mixins.scss';

.questionaire-sidebar {
  @include scrollbar-small;
  transition: width 250ms ease-in;
  position: sticky;
  top: 135px;
  padding-left: 15px;
  padding-right: 15px;
  width: 28%;
  display: flex;
  flex-direction: row;
  max-height: 60vh;
}

.questionaire-sidebar__controls {
  width: 64px;

  .questionaire-sidebar__controls-close {
    height: 64px;
    width: 100%;
    border: none;
    color: $smv-font;
    background-color: $smv-background;

    i {
      transition: transform ease 250ms;
    }

    &:hover {
      background-color: $smv-background-light;
    }
    &:focus {
      outline: none;
    }
  }
}

.questionaire-sidebar__column-left {
  border-top: 1px solid $smv-background-light;
  border-left: 1px solid $smv-background-light;
  border-bottom: 1px solid $smv-background-light;
  overflow-y: auto;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  // transition: width 80ms ease-in-out;

  .node-icon {
    color: $smv-font;
  }
}

.questionaire-sidebar__column-right {
  min-width: 64px;
  max-width: 64px;
  border: 1px solid $smv-background-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  .iconprovider_with_name {
    height: 100%;
    min-height: 56px;
    .iconprovider_with_name__name {
      font-size: 0.75rem;
      margin-top: 2px;
    }
    img {
      height: 14px;
    }
  }
}

.questionaire-sidebar__column-right--empty-que {
  // background-color: $smv-primary-light;
}

.questionaire-sidebar--closed {
  width: 94px;
  .questionaire-sidebar__top {
    border-top: 1px solid transparent;
    border-left: 1px solid transparent;
  }
  .questionaire-sidebar__column-left {
    border-left: 1px solid transparent;
    border-bottom: 1px solid transparent;
    border-top: 1px solid transparent;
  }
  .top-controls__editgroup {
    width: 0px;
    overflow: hidden;
    opacity: 0;
  }
  .questionaire-sidebar__controls-close {
    i {
      transform: rotate(180deg);
    }
  }
}
