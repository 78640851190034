@import './colors.scss';

// scrollbar styling for small-width scrollbars
@mixin scrollbar-small {
  scroll-behavior: smooth;
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    height: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: $smv-background-light;
    border-radius: 0;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $smv-background;
    border-radius: 0;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $smv-background-dark;
  }
}
