@import '~smv-assets/css/variables.scss';

$querybuilder-dotted-lines: 1px dotted $smv-background;

.querybuilder {
  margin-left: 0px;
  width: 100%;
  max-width: 100%;

  .form-select {
    font-size: 0.75rem;
  }

  .querybuilder__group-header {
    width: 100%;
    padding-top: 0.5rem;
  }

  .querybuilder__group-rules:not(:nth-of-type(1)) {
    .querybuilder__group-header {
      position: relative;

      .col-12 {
        padding-left: 8px;
        margin-left: 8px;

        &:before {
          position: absolute;
          content: '';
          width: 10px;
          border-top: $querybuilder-dotted-lines;
          height: 1px;
          left: 5px;
          margin-top: -2px;
        }
      }
    }
  }

  .querybuilder__group-rules {
    padding-top: 12px;
    padding-bottom: 12px;
    width: calc(100% - 9px);
    padding-left: 8px;
    margin-left: 12px;
    border-left: $querybuilder-dotted-lines;

    &:first-of-type {
      border-left: none;
    }
  }

  .querybuilder__group-rule {
    width: 100%;
  }

  .querybuilder__group-rules > .querybuilder__group-rule:last-of-type {
    .querybuilder__group-rule-line-container {
      width: 20px;
      border-left: none;
      position: relative;

      &:after {
        left: 0;
        content: '';
        border-left: $querybuilder-dotted-lines;
        position: absolute;
        height: 26%;
        top: 0;
      }
    }
  }

  .querybuilder__group-rules:not(:first-of-type):last-of-type {
    border-left: none;
    position: relative;

    &:after {
      left: 0;
      content: '';
      border-left: $querybuilder-dotted-lines;
      position: absolute;
      height: 34px;
      top: 0;
    }
  }

  .querybuilder__group-rule-line-container {
    position: relative;
    border-left: $querybuilder-dotted-lines;
    margin-left: 8px;
    padding-left: 8px;

    // col-classes from bootstrap
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    &:before {
      position: absolute;
      content: '';
      width: 8px;
      border-top: $querybuilder-dotted-lines;
      height: 2px;
      left: 0px;
      margin-top: 18px;
    }
  }

  //validation warning border
  .querybuilder__select--invalid {
    border: 1px solid $smv-warning;
  }
}
