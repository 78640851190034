@import '~smv-assets/css/variables.scss';

.empty-node {
  min-height: 48px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .plus-container {
    height: 60px;
  }
}

.empty-node--isOver {
  background-color: $smv-droparea;
  color: $smv-white;
}

.empty-node--empty-que {
  border: 1px solid $smv-secondary-light;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.questionnaire__content {
  .empty-node--isOver {
    height: 80px;
    &:after {
      font-size: 2.5rem;
    }
  }
}
