@import '~smv-assets/css/variables.scss';
@import '~smv-assets/css/mixins.scss';

.tables-page {
  font-size: 0.75rem;
  h2 {
    font-size: 1.75rem;
    line-height: 1;
  }
  h5 {
    font-size: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  button {
    font-size: 0.75rem;
  }

  .tables-sidebar-left,
  .tables-sidebar-right {
    @include scrollbar-small;
    // use mixin for the 198px as its calculated by the height of headline and padding of base-content?
    height: calc(100vh - 198px);
    .fa-chevron-up,
    .fa-chevron-right,
    .fa-chevron-down {
      font-size: 0.75rem;
      min-width: 12px;
      display: flex;
      justify-content: center;
    }

    .list-group--first-lvl {
      overflow-y: auto;
      max-height: calc(100% - 28px);
      border-radius: 0;
      margin-top: -2px;
    }
  }

  .tables-sidebar-left {
    // max-height: 80vh;
    transition: max-height ease-in-out 200ms, flex ease-in-out 100ms, max-width ease-in-out 100ms;
    padding-right: 0;

    &--closed {
      max-height: 0;
    }

    @media (min-width: $breakpoint-md) {
      // max-height: 80vh;
      position: sticky;
      top: 88px;
    }

    .list-group-item {
      border-radius: 0;
    }
  }

  .tables-sidebar-right {
    transition: max-height ease-in-out 200ms, flex ease-in-out 100ms, max-width ease-in-out 100ms;
    // max-height: 80vh;
    padding-right: 0;

    &--closed {
      max-height: 0;
    }

    @media (min-width: $breakpoint-md) {
      // max-height: 80vh;
      position: sticky;
      top: 88px;
    }

    .tables-sidebar-right__content {
      border: 1px solid $smv-background-light;
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      padding: 0px 4px 0 4px;

      .list-group-item {
        border: none;
      }

      .custom-control-label {
        padding-top: 3px;
      }
    }

    .tables-save-buttons {
      padding-top: 4px;
      position: sticky;
      bottom: 0;
      background-color: white;
      z-index: 2;
      border-top: 1px solid $smv-background-light;
    }
  }

  .tables-page__table {
    transition: flex ease-in-out 100ms, max-width ease-in-out 100ms;

    .tables-page__headline {
      &--unsaved-changes {
        font-style: italic;
      }
      .static-filter-warning {
        font-size: 0.875em;
        color: $smv-warning;
      }
    }

    .breadcrumb {
      background-color: $smv-white;
      padding: 0;
    }

    .tables-page__headline-unsaved-changes-indicator {
      color: $smv-warning;
    }
  }

  .loading-overlay {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    z-index: 5;
  }
}
