@import '~smv-assets/css/variables.scss';

.result-table-container {
  width: 100%;
  max-height: 65vh;
  display: flex;
  flex-direction: column;
  border-radius: 0.25rem;
  overflow-y: hidden;
  position: relative;
  overflow-y: auto;

  .result-table-listing {
    display: table;

    .result-table--head {
      display: table-header-group;
      position: sticky;
      top: 0;

      background-color: $smv-white;

      font-size: 1.2em;
      font-weight: bold;

      &--can-drop {
        // background-color: rgba($smv-secondary, 0.5) !important;
        background-color: #afc1dc !important;
        border: 2px dashed $smv-secondary;
      }

      &--is-over {
        background-color: rgba($smv-primary, 1) !important;
      }

      .result-table--head-cell {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
    }

    .result-table--body {
      display: table-header-group;

      .result-table--row:nth-of-type(odd) {
        background-color: rgba($color: #000000, $alpha: 0.05);
      }
      .result-table--row:hover {
        background-color: rgba($color: #000000, $alpha: 0.075);
      }
    }

    .result-table--row {
      display: table-row;
      flex-direction: row;
      // border: 0.5px solid grey;
    }

    .result-table--cell {
      display: table-cell;
      vertical-align: middle;
      padding: 12px 4px;
    }
  }
}

.result-table-listing-footer {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 0px;

  &--limit {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
  &--pagination {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
  }
}
