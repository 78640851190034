@import '~smv-assets/css/variables.scss';

.choice-config-container {
  // is only shown when hovering over .smoove-question-container
  opacity: 0;
  transition: opacity 250ms ease-in-out;
  color: $smv-font;
  display: flex;

  .fal {
    color: $smv-background;
  }
}
