@import '~smv-assets/css/variables.scss';
.config-modal-container {
  .modal-header {
    padding: 8px 24px 0 24px;
    .modal-title {
      max-width: 100%;
    }
  }

  .config-modal-question-navigation-container {
    position: relative;
    z-index: 2;

    .config-modal-question-navigation-container__btn-previous,
    .config-modal-question-navigation-container__btn-next {
      position: fixed;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: white;
      transition: background-color 0.3s, color 0.3s, border-color 0.3s;
      border: 1px solid $smv-background;
      cursor: pointer;

      &:hover {
        border-color: $smv-secondary-light;
        background-color: $smv-secondary-light;
        color: white;
      }
    }

    .config-modal-question-navigation-container__btn-previous {
      left: 20px;

      &--disabled {
        display: none;
      }
    }

    .config-modal-question-navigation-container__btn-next {
      right: 20px;

      &--disabled {
        display: none;
      }
    }
  }
}

.modal__title-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  padding: 12px 12px 12px 0;
  border-bottom: 2px solid $smv-primary;
  width: 90%;
  color: $smv-font;
  flex: 1 1 auto;

  .modal__title-info {
    flex: 0 0 auto;
    margin-right: 5px;
  }
  .modal__title-info-varname {
    font-weight: normal;
  }

  .modal__title {
    flex: 0 1 auto;
    overflow: hidden;
  }
}

.tab {
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
    color: $smv-secondary;
  }

  &.tab--active {
    border-bottom: 2px solid $smv-secondary;
  }
}
