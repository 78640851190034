@import '~smv-assets/css/variables.scss';

.smv-general-survey-settings {
  .breadcrumb {
    padding: 0;
    background-color: $smv-white;
  }
  .datepicker-calendar-icon {
    color: $smv-secondary;
  }
  .survey-status {
    .fa-hat-wizard,
    .fa-pencil-ruler {
      color: $smv-warning;
    }
    .fa-rocket-launch {
      color: $smv-secondary;
    }
    .fa-flag-checkered {
      color: $smv-primary;
    }
  }

  .react-datepicker-wrapper {
    width: auto;
  }

  .react-datepicker__input-container {
    input {
      min-width: 220px;
      border-radius: 0.25rem;
      padding: 4px 8px;
      border: 1px solid $smv-background;
      color: $smv-font;
    }
  }

  .survey-thumbnails-container {
    display: flex;
    justify-content: flex-start;

    .survey-thumbnails-container__thumb {
      margin-right: 8px;
      max-width: 150px;
      max-height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $smv-background-light;

      cursor: pointer;
      img {
        object-fit: cover;
        object-position: center center;
        max-height: 100%;
        max-width: 100%;
      }
    }
    .survey-thumbnails-container__empty-thumb {
      margin-right: 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $smv-secondary;
      width: 100px;
      height: 100px;
      border: 1px solid $smv-background-light;
    }
  }
}
