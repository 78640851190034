@import '~smv-assets/css/variables.scss';

.new-item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 4px 0;

  .new-item__label {
    flex: 1;
  }
}
.fixed-loading-indicator {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(200, 200, 200, 0.8);
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100;
}
