@import '~smv-assets/css/variables.scss';

.weight-tables-modal {
  .apply-to-list {
    background-color: $smv-background-light;
    max-height: 200px;
    overflow: auto;
    padding: 4px 8px;
    border: 1px solid $smv-background-light;
  }
}
