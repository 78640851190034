@import '~smv-assets/css/variables.scss';

.report-item {
  padding: 8px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .report-item__link {
    max-width: 100%;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  .report-item__label {
    padding-left: 4px;
    color: $smv-font-dark;
    padding-right: 4px;
  }

  .fa-circle {
    color: $smv-secondary;
    transition: color ease-in-out 200ms, font-weight ease-in-out 200ms;
    &.fas {
      color: $smv-primary-light;
    }
  }

  .report-item__empty-indicator {
    margin-left: 6px;
    padding-left: 6px;
    border-left: 1px solid $smv-background-light;
  }
}
