@import '~smv-assets/css/variables.scss';

.smv-filter-list {
  padding: 0.75rem 0.5rem;

  .smv-filter-list__filter-element {
    border-top: 1px solid $smv-background-light;
    padding-top: 0.5rem;
    margin-top: 0.5rem;

    &__head {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &--label {
        display: flex;
        align-items: center;
        flex-grow: 1;
        cursor: pointer;

        .fa-chevron-right,
        .fa-chevron-down {
          width: 15px;
        }
      }

      &--clear {
        cursor: pointer;
      }
    }
  }
}
