@import '~smv-assets/css/variables.scss';

.media-library-modal {
  .media-library-toolbar {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    margin-bottom: 8px;
    .media-library-toolbar__searchbar {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;

      input:focus {
        box-shadow: none;
        border-color: $smv-background-dark;
      }

      button {
        min-width: 36px;
        min-height: 36px;
      }
    }
    .media-library-toolbar__filters {
      flex: 1 1 auto;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
    }
  }

  .medialist {
    display: flex;
    flex-wrap: wrap;
    max-height: 50vh;
    overflow-y: auto;
    border: 1px solid $smv-background-light;
    padding: 8px;
  }

  .medialist-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 160px;
    border: 1px solid $smv-background-light;
    margin-bottom: 16px;
    margin-right: 16px;
    border: 2px solid $smv-background-light;

    &__loading {
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 3;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: rgba(0, 0, 0, 0.3);
    }

    .medialist-item__image {
      width: 100%;
      aspect-ratio: 1/1;
      object-fit: contain;
      padding: 8px;
      transition: transform ease-in-out 150ms;
    }
    .medialist-item__av-media {
      width: 100%;
      height: 100%;
      min-height: 140px;
      padding: 8px;
      transition: transform ease-in-out 150ms;
    }

    .medialist-item__label {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      padding: 2px 4px;
      font-size: 14px;
      border-top: 1px solid $smv-background-light;
      background: $smv-background-light;
    }

    .medialist-item__buttons {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.3);
      opacity: 0;
      transition: opacity 150ms ease-in-out;

      .medialist-item__button {
        padding: 6px;
        margin: 0 4px 4px 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.75rem;
      }

      .medialist-item__buttons-top-right {
        position: absolute;
        top: 4px;
        right: 4px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        .medialist-item__button {
          width: 28px;
          height: 28px;
        }
      }

      .medialist-item__cta-buttons {
        height: calc(100% - 0px);
        display: flex;
        justify-content: center;
        align-items: center;

        .medialist-item__button {
          width: 44px;
          height: 44px;
          padding: 8px;
          font-size: 1.25rem;
        }
      }
    }
    &:hover {
      .medialist-item__image,
      .medialist-item__av-media {
        transform: scale(1.08);
        cursor: pointer;
      }
      .medialist-item__buttons {
        opacity: 1;
      }
    }

    &--selected {
      border: 2px solid $smv-secondary;
    }
  }
  .media-library-upload-stage {
    padding: 8px 8px 4px 8px;
    background: $smv-background-lighter;
    border: 1px solid $smv-background-light;
    margin-bottom: 8px;
    display: flex;
    flex-wrap: wrap;

    .media-library-upload-stage__item {
      padding: 4px;
      margin-bottom: 4px;
      border: 1px solid $smv-background;
      margin-right: 4px;
      position: relative;
      background-color: $smv-white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 120px;

      img {
        max-width: 100%;
        max-height: 100%;
      }

      &--duplicate {
        border: 4px solid $smv-warning;
        padding: 0;

        &:after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          top: 0;
          content: '';
          background-color: rgba(0, 0, 0, 0.4);
          z-index: 1;
        }

        .duplicate-image-warning {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          font-size: 0.75rem;
          text-align: center;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          z-index: 2;
          top: 0;
          background-color: rgba(0, 0, 0, 0.3);
        }
      }

      .av-media {
        height: 100px;
        width: 100px;
      }
      .av-media-name {
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-top: 8px;
      }

      .image-remove-btn,
      .image-edit-btn {
        position: absolute;
        top: 8px;
        right: 8px;
        display: none;
        align-items: center;
        justify-content: center;
        padding: 4px 6px;
        z-index: 3;
      }
      .image-edit-btn {
        right: 38px;
      }

      &:hover {
        .image-remove-btn,
        .image-edit-btn {
          display: flex;
        }
      }
    }
  }
  .media-library__sort-container {
    margin-bottom: 8px;
    display: flex;
    align-items: center;

    .sort-container-item {
      cursor: pointer;
      margin-right: 12px;
      font-size: 1.25rem;

      &--active {
        span {
          font-weight: bold;
        }
      }
    }
  }
  .media-library-edit-image-container {
    height: 140px;
    box-sizing: border-box;
    display: flex;

    > img {
      box-sizing: border-box;
      display: block;
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center;
    }
  }
}

.ReactModal__Overlay {
  z-index: 2000 !important;
}
