// mla color variables as defined in Figma

$smv-primary-dark: #647b14;
$smv-primary: #8aa91b;
$smv-primary-light: #98b91e;
$smv-primary-lighter: #c0e146;
$smv-primary-lightest: #cfe972;
$smv-primary-bg: #f0f8d3;

$smv-secondary-dark: #4669a0;
$smv-secondary: #5e82b9;
$smv-secondary-light: #829ec9;
$smv-secondary-lighter: #a6bad8;
$smv-secondary-lightest: #cad6e8;
$smv-secondary-bg: #dbe3f0;

$smv-danger-dark: #991415;
$smv-danger: #c51a1b;
$smv-danger-light: #e43031;
$smv-danger-lighter: #ea5d5e;
$smv-danger-bg: #f9d2d2;

$smv-warning-dark: #b27700;
$smv-warning: #e39700;
$smv-warning-light: #ffb219;
$smv-warning-lighter: #ffc34c;
$smv-warning-bg: #ffeecc;

$smv-background-dark: #b2b2b2;
$smv-background: #cccccc;
$smv-background-light: #e5e5e5;
$smv-background-light-2: #eeeeee;
$smv-background-lighter: #fafafa;

$smv-font-dark: #4c4c4c;
$smv-font: #666666;
$smv-font-light: #808080;
$smv-font-lighter: #b4b4b4;

$smv-white: #ffffff;

//defines backgroundColor, hover-colors and color (textcolor) for each color
$smv-clr-map: 'green' $smv-primary $smv-primary-light $smv-white, 'primary' $smv-primary $smv-primary-light $smv-white,
  'blue' $smv-secondary $smv-secondary-light $smv-white, 'secondary' $smv-secondary $smv-secondary-light $smv-white,
  'red' $smv-danger $smv-danger-light $smv-white, 'danger' $smv-danger $smv-danger-light $smv-white,
  'orange' $smv-warning $smv-warning-light $smv-white, 'warning' $smv-warning $smv-warning-light $smv-white,
  'light' #f8f9fa #e2e6ea $smv-font, 'lightgrey' $smv-background $smv-background-light $smv-white,
  'grey' $smv-font $smv-font-light $smv-white;

//element/component specific colors
$smv-droparea: $smv-secondary-light;

$smv-ai-gradient: linear-gradient(90deg, #7b2ff7, #00c6ff);
