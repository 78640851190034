@import '~smv-assets/css/variables.scss';

.dynamic-split-list {
  display: block;
  width: 100%;
}

.dynamic-split-list-element {
  display: flex;
  align-items: center;
  border: 1px solid transparent;
  padding: 4px;
  justify-content: space-between;
  min-height: 42px;

  &--active {
    border: 1px solid $smv-secondary;
  }
}

.dynamic-split-list-element__label {
  flex: 1 1 auto;
}
