@import '~smv-assets/css/variables.scss';

.conditional-format__drag-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 4px;
}

.conditional-format__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;

  .conditional-format__condition-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin-top: 0.25rem;
    }

    @media (min-width: $breakpoint-xl) {
      flex-direction: row;
      align-items: center;
      > * {
        margin-top: 0;
      }
    }
  }

  .conditional-format__input {
    max-width: 200px;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    height: 32px;
  }
  .conditional-format__value-input {
    max-width: 80px;
    height: 32px;
    font-size: 0.75rem;
    margin-right: 0.5rem;
  }
  .smv-custom-dropdown {
    border: 1px solid $smv-background;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    height: 32px;
    display: flex;
    align-items: center;
    max-width: 200px;
    .smv-custom-dropdown {
      border: none;
      width: auto;
    }
  }
  .conditional-format__color-dropdown {
    margin-left: 0.25rem;
    width: 28px;
    border: none;
  }
  .conditional-format__backgroundcolor {
    display: inline-block;
    border: 1px solid black;
    border-radius: 2px;
    width: 20px;
    height: 20px;

    &--empty {
      border: none;
    }
  }

  .conditional-format__buttons {
    display: flex;
    flex-direction: row;
  }
}
