@import 'colors.scss';

@mixin badge($colorName, $backgroundColor, $hoverColor, $color) {
  .badge-,
  .badge-smv- {
    &#{$colorName} {
      color: $color;
      background-color: $backgroundColor;
      transition: background-color 200ms ease-in-out;

      &:hover {
        cursor: default;
        //   background-color: $hoverColor;
      }

      &-outline {
        color: $backgroundColor;
        background-color: transparent;
        border: 1px solid $backgroundColor;
        transition: all 200ms ease-in-out;

        // &:hover {
        // cursor: pointer;
        // background-color: $color;
        // color: $smv-white;
        // }
      }
    }
  }
}

@each $colorName, $backgroundColor, $hoverColor, $color in $smv-clr-map {
  @include badge($colorName, $backgroundColor, $hoverColor, $color);
}
