@import '~smv-assets/css/variables.scss';

.formula-builder {
  width: 100%;
  min-height: 42px;
  border: 1px solid $smv-background;
  padding: 0 4px 4px 4px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.formula-builder__selected-stage {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}

.formula-builder__draggable-element {
  display: inline-block;
}

.formula-builder__element {
  margin: 4px 4px 0 0;
  min-width: 32px;
  height: 32px;
  padding: 4px 0px;
  background-color: $smv-white;
  color: $smv-secondary;
  font-weight: bold;
  border-radius: 4px;
  border: 2px solid $smv-secondary;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: normal;

  .formula-builder__element-label {
    text-align: center;
    min-width: 24px;
    padding: 0 8px;
  }

  &--new {
    padding: 0px 4px;
    border-radius: 50%;
    border-color: $smv-primary;
    background-color: $smv-primary;
    color: $smv-white;
    cursor: pointer;
  }

  &--delete {
    border-color: $smv-danger;
    color: $smv-danger;
  }

  &--active {
    border-color: $smv-warning;
    color: $smv-warning;
  }

  &--value {
    background-color: $smv-white;
    border: 2px solid $smv-background;
    color: $smv-background-light;
  }

  &--math-operator {
    background-color: $smv-white;
    border: 2px solid $smv-background;
    color: $smv-font;
    span {
      font-weight: bold;
    }
  }

  &--bracket {
    background-color: $smv-white;
    border: 2px solid $smv-warning;
    color: $smv-warning;
    span {
      font-weight: bold;
    }
  }

  input {
    color: $smv-font;
    border-color: $smv-white;
    min-width: 24px;
    max-width: 80px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    outline: none;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
    padding: 0 8px;

    // hide inc/dec numbers
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }

    &:focus {
      background-color: white;
      color: black;
    }
  }

  .formula-builder__element-delete-btn {
    transition: width 150ms ease-in-out;
    color: $smv-danger;
    z-index: 1;
    margin: 0 auto;
    position: absolute;
    opacity: 0;
    background-color: $smv-white;
    border: 2px solid $smv-danger;
    transform: translateY(0px);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    border-radius: 4px;
    padding: 2px;

    &:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 10px;
      height: 8px;
      width: 8px;
      background: white;
      box-sizing: border-box;
      transform: rotate(45deg) translate(-50%);
      border-bottom: inherit;
      border-right: inherit;
      box-shadow: inherit;
      z-index: 2;
    }
  }

  &:hover {
    .formula-builder__element-delete-btn {
      transform: translateY(-36px);
      opacity: 1;
      z-index: 3;
      cursor: pointer;
    }
  }
}

.formula-builder-toolbar {
  border: 1px solid $smv-background;
  border-top: none;
  overflow: hidden;

  &--open {
    overflow: auto;
  }

  .formula-builder-toolbar__operators {
    display: flex;
    flex-direction: row;
    padding: 0 4px 4px 4px;
  }

  .formula-builder__element {
    background-color: $smv-primary;
    border-color: $smv-primary;
    color: $smv-white;
    border-radius: 4px;

    .formula-builder__element-label {
      min-width: 24px;
      padding: 0 8px;
    }
    &:hover {
      cursor: pointer;
      background-color: $smv-primary-light;
    }
  }
}

.formula-builder__table-list {
  max-height: 50vh;
  overflow: auto;
  padding: 0 8px;

  .table-list__element {
    padding: 4px;
    margin-left: 8px;
    cursor: pointer;

    &--selected {
      background-color: $smv-secondary;
      color: white;
    }

    &:hover {
      background-color: $smv-secondary;
      color: white;
    }

    &--headline {
      margin-top: 4px;
      font-weight: 500;
      margin-left: 0px;

      &:hover {
        background-color: $smv-white;
        color: inherit;
        cursor: initial;
      }
    }
  }
}
