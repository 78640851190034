@import '~smv-assets/css/variables.scss';

.result-table-empty--container {
  width: 100%;
  height: 50vh;
  color: $smv-font-lighter;

  .result-table-empty--card {
    width: 100%;
    height: 100%;
  }
}
