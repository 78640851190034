@import '~smv-assets/css/variables.scss';

.smoove-container-container {
  margin-bottom: 8px;

  .smoove-container-container__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    width: 100%;
    border: 1px solid $smv-background-light;
    padding: 4px 4px 4px 8px;
  }

  .container-icons {
    display: flex;
    flex: 1 0 auto;
    flex-wrap: nowrap;
    align-items: center;
  }
}
