.project-widget {
  height: 100%;

  .card-img-top--wizard-image {
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}
