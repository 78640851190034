@import '~smv-assets/css/variables.scss';

.smv-dashboard-element-menu {
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: 2;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  font-size: 1rem;
  color: $smv-secondary;
  background-color: $smv-white;
  border-radius: 0.25rem;
  min-height: 40px;
  // transition opacity in here as well, set in Reports.scss
  transition: background-color 200ms ease-in-out, color 200ms ease-in-out, opacity 200ms ease-in-out;

  > .smv-dashboard-element-menu__item {
    padding-left: 5px;
    padding-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: color 250ms ease-in-out;

    &:hover {
      color: $smv-warning;
    }
  }

  &:hover {
    cursor: pointer;
  }
}
