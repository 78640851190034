@import 'colors.scss';

.menu-item {
  position: relative;
  font-size: 1.1em;
  padding: 2px 12px;
  box-sizing: content-box;

  .nav-link {
    &::before {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 50%;
      height: 2px;
      width: 0px;
      background-color: $smv-danger;
      transition: left ease-out 250ms, width ease-out 250ms;
    }

    &.active::before {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 30%;
      height: 2px;
      width: 40%;
      background-color: $smv-danger;
      transition: left ease-out 250ms, width ease-out 250ms;
    }

    &:hover::before {
      left: 0;
      height: 2x;
      width: 100%;
      transition: left ease-in 250ms, width ease-in 250ms;
    }
  }
}
