@import '~smv-assets/css/variables.scss';

.dynamic-split-config-settings {
  border: 1px solid $smv-secondary;
  border-radius: 0.25rem;
  padding: 8px;
  background-color: $smv-background-light;
  height: 100%;
}

.split-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px;
  min-height: 42px;
  margin-left: 0;
  margin-right: 0;

  &--hidden {
    color: $smv-background-dark;
    // background-color: $smv-background-lighter;
  }
}
