@import '~smv-assets/css/variables.scss';

.ai-translation-result {
  display: flex;
  border: 1px solid;
  border-image: $smv-ai-gradient 1;
  padding: 0 0.25rem;
  margin-bottom: 1rem;

  .ai-translation-result__text {
    > p {
      margin-bottom: 0;
    }
  }
}
