@import '~smv-assets/css/variables.scss';

.smv-result-report {
  min-height: 400px;
  background-color: $smv-background-lighter;
  border: 1px solid $smv-background-light;
  max-width: 100%;
  border-top: none;

  .smv-result-report__page--title {
    padding: 1rem 0 0 0.5rem;
  }

  .react-grid-layout {
    min-height: 74vh;
    max-width: 100%;
  }
}

.page-tabs-container {
  max-width: 100%;
}

.smv-result-report--empty {
  border: 1px solid #ebebeb;
  border-top: none;
  position: relative;
  min-height: 410px;

  .react-grid-layout {
    min-height: 74vh;
  }
}

.smv-result-report-element {
  .smv-dashboard-element-menu,
  .react-resizable-handle {
    opacity: 0;
  }
  .react-resizable-handle {
    transition: opacity 200ms ease-in-out;
  }

  &:hover {
    .smv-dashboard-element-menu,
    .react-resizable-handle,
    .dashboard-alignment-buttons {
      opacity: 1;
    }
  }

  .delete-handle {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 250ms ease-in-out;

    &:hover {
      color: $smv-warning;
    }
  }
  &.react-draggable-dragging {
    .smv-drawer {
      display: none;
    }
  }
  .react-resizable-handle-se {
    z-index: 2;
  }
  &.smv-scroll-indicator {
    box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.5);
  }
  .dashboard-element__placeholder-loading {
    background: white;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.smv-toggle-reports-btn {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ebebeb;
  margin-right: 0.25rem;
  border-radius: 0.25rem;

  &:hover {
    cursor: pointer;
  }
  i {
    font-size: 2rem;
    color: $smv-secondary;
  }
}

.smv-tabs-container {
  position: relative;

  .tabs-container__sticky {
    position: sticky;
    top: 119px;
    z-index: 3;
    background-color: $smv-white;
    border-bottom: 1px solid $smv-background-light;
  }

  .smv-tabs-container__tab-nav {
    display: flex;
    justify-content: flex-start;
    position: relative;
    overflow-x: auto;
    flex-wrap: nowrap;
    z-index: 2;
    border-bottom: 0;
    margin-bottom: -1px;
  }

  .smv-tabs-container__tab-nav-newtab {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $smv-background;
    color: $smv-white;
    width: 42px;
    max-width: 42px;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
    flex: 1 0 auto;

    .fal {
      padding-top: 4px;
    }

    &:hover {
      background-color: $smv-secondary;
      cursor: pointer;
    }
  }

  .smv-tabs-container__tab-head {
    border: 1px solid $smv-background-light;
    border-bottom: none;
    border-top-right-radius: 0.25rem;
    border-top-left-radius: 0.25rem;
    display: flex;
    align-items: center;
    border-bottom: 1px solid $smv-background-light;

    &:hover {
      cursor: pointer;
      background-color: $smv-background-lighter;
    }

    &--active {
      background-color: $smv-background-lighter;
      border-bottom: 1px solid $smv-background-lighter;
      z-index: 4;
    }

    .smv-tabs-container__tab-title {
      width: 72px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
