@import '~smv-assets/css/variables.scss';

.static-filters-warning {
  background-color: $smv-background-dark;
  display: inline-block;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
  // border: 1px solid transparent;
  color: white;
}
