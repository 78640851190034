@import '~smv-assets/css/variables.scss';

.basic-search-input {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  button {
    width: 38px;
  }

  .basic-search-input__input {
    border: none;
    border-bottom: 1px solid $smv-background-light;
    box-shadow: none;
    outline: none;
    border-radius: 0;

    &:focus {
      border-bottom: 1px solid $smv-background-dark;
    }
  }
}
