@import '~smv-assets/css/variables.scss';

$greyscale: $smv-background-light, $smv-background-light-2, #f3f3f3, #f9f9f9, $smv-background-lighter,
  $smv-background-lighter, $smv-background-lighter, $smv-background-lighter, $smv-background-lighter;

.smoove-question-container,
.smoove-page-container,
.smoove-container-container {
  position: relative;

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    top: -3px;
    right: -10px;
    height: 0;
    opacity: 0.2;
    border-radius: 3px;

    background-color: transparent;
    transition: background-color 500ms, height 0ms 500ms;
  }

  @for $i from 1 through 9 {
    &.smoove-level-#{$i} {
      &:after {
        left: $i * -12px + 4px;
      }
      .smoove-question-container__container,
      .smoove-container-container__container,
      .smoove-page-container__group {
        background-color: nth($greyscale, $i);
        input,
        .inline-edit {
          background-color: nth($greyscale, $i);
        }
      }
    }
  }

  .smoove-container-container__container {
    .que-element-title {
      flex-grow: 0;
    }
  }

  &.smv-scroll-indicator {
    &:after {
      z-index: 2;
      height: calc(100% + 10px);
      background-color: $smv-primary;
      transition: background-color 250ms;
    }
  }
}

.smoove-question-container:hover::before,
.smoove-page-container:hover::before,
.smoove-container-container:hover::before {
  height: 100%;
  width: 2px;
  transition: background-color ease 1s, height ease-out 500ms;
}

.smoove-container-container__group,
.smoove-page-container__group {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  .container__title,
  .page__title {
    max-width: 100%;
  }
}

/*.smoove-question-container:hover {*/
/*box-shadow: 1px 2px 10px rgba(0,0,0,.3);*/
/*}*/

.smoove-question-container:hover::before {
  background-color: $smv-primary;
}

.smoove-page-container:hover::before {
  background-color: $smv-danger;
}

.smoove-container-container:hover::before {
  background-color: $smv-warning;
}

.smoove-questionnaire-controls {
  transition: all linear 500ms;
}

.smoove-editable-text:hover {
  text-decoration: underline;
}
.smoove-ql {
  margin-bottom: 0px;
}
.ql-tooltip {
  z-index: 1010;
}

.smoove-ql .ql-container {
  font-size: inherit;
}

.smoove-ql .ql-editor {
  padding: 4px;
  border: 1px solid transparent;
  border-radius: 2px;
}

.smoove-ql.no-padding .ql-editor {
  padding: 2px;
}

.smoove-ql .ql-editor:active,
.smoove-ql .ql-editor:focus {
  border: 1px solid $smv-background-light;
}

.smoove-ql .ql-editor:hover:not(:focus) {
  border: 1px solid $smv-background-light;
}

.smoove-ql.help-text {
  font-size: 14px;
  font-weight: 400;

  .ql-editor {
    padding: 2px 5px;
  }
}

.smoove-ql.ql-disabled {
  border: none;
  .ql-container {
    border: none;
  }
  .ql-editor {
    border: none !important;
  }
}

.questionnaire__content {
  width: 72%;
  flex: 1 1 auto;
}

.smoove-question-container__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  width: 100%;
  padding: 4px 4px 4px 8px;
  border: 1px solid $smv-background-light;
}

.ql-editor.ql-blank::before {
  left: 7px;
}
.smoove-ql.help-text {
  .ql-editor.ql-blank::before {
    left: 6px;
  }
}

.smv-questionnaire {
  font-size: 0.875rem;

  .smv-questoinnaire__head {
    position: sticky;
    top: 65px;
    background: white;
    z-index: 2;
    padding-top: 10px;
    h2 {
      font-size: 1.75rem;
    }
  }

  .smoove-container-container {
    .smoove-container-container__collapse {
      border-left: 1px solid $smv-background-light;
      padding: 8px 0px 0px 8px;
      margin-bottom: 8px;
    }
  }

  .smoove-question-container__selection {
    border-left: 1px solid $smv-background-light;
    margin-left: 0px;
    padding-left: 0px;
    margin-bottom: 8px;

    .collapse,
    .collapsing {
      width: 100%;
      padding-left: 0;
    }

    .que-selector-container {
      padding: 8px 16px 8px 16px;
      border-bottom: 1px solid $smv-background-light;
      border-right: 1px solid $smv-background-light;
    }
  }

  .edit-lounched-survey-switch {
    label {
      cursor: pointer;
      padding-top: 2px;
    }
  }

  // decrease initial collapse animation time for faster scroll animation
  .collapsing {
    transition: height 100ms ease;
  }
  .rotate {
    transition: all 100ms ease-in-out;
  }
}
