@import '~smv-assets/css/variables.scss';

.view__drag-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  padding: 4px;
}

.bla {
  display: flex;
  flex-direction: column;
}

.view__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  padding: 10px;
  // padding-left: 12px;
  // margin-left: 8px;

  .view__label_input {
    max-width: 200px;
    font-size: 0.75rem;
    margin-right: 0.5rem;
    height: 32px;
  }

  .view__label-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .view__color-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > * {
      margin-top: 0.25rem;
    }

    @media (min-width: $breakpoint-xl) {
      flex-direction: row;
      align-items: center;
      > * {
        margin-top: 0;
      }
    }
  }

  .view__color-dropdown {
    margin-left: 0.25rem;
    width: 28px;
    border: none;
  }
  .view__backgroundcolor {
    display: inline-block;
    border: 1px solid black;
    border-radius: 2px;
    width: 20px;
    height: 20px;

    &--empty {
      border: none;
    }
  }

  .view__buttons {
    // margin-left: 3rem;
    display: flex;
    flex-direction: row;
    padding: 10px;
  }
}
